import {
  SGLLogo,
  SGLLogo2x,
  SGLHeader,
  SGLHeader2x,
  SGLSubHeader,
  SGLSubHeader2x
} from '@src/assets/images';

/**
 * Returns value of global or environment variable.
 * See also docker-entrypoint and index.html.
 */
export const getEnvVal = (name: string, deflaut: string | undefined = undefined) => {
  if (name in window) {
    return window[name];
  }
  if (name in process.env) {
    return process.env[name];
  }
  return deflaut;
};

/*
 * Configuration settings.
 */
export const SITE_TITLE = getEnvVal('SITE_TITLE', 'eBirdie palvelu');
const API_URL_PRODUCTION = 'https://production.api.address/';
const API_URL_QA = 'https://qa.api.address/';
const API_URL_DEVELOPMENT = 'http://localhost:8080/';
export const SUPPORT_EMAIL_ADDRESS = getEnvVal('SUPPORT_EMAIL_ADDRESS', 'support@email.address');
export const GMAPS_API_KEY = getEnvVal('GOOGLE_MAPS_API_KEY', 'your_google_maps_key');
export const DEFAULT_INPUT_LEFT_MARGIN = '1.8em';
export const TITLE_DIVIDER = ' | ';

/*
 * Backend API Routes
 */
export const APIRoute = {
  POST_RESET_PASSWORD: 'admin/1/resetPassword',
  POST_LOGOUT: 'admin/1/logout',
  POST_LOGIN: 'admin/1/login',
  GET_LOGIN_CHECK: 'admin/1/loginCheck',
  GET_USERS: 'admin/1/users',
  SEARCH_USERS: 'admin/1/users/search',
  GET_USER: 'admin/1/user/',
  CLUB_COURSE: 'admin/1/course',
  CLUB_ORIGINAL_COURSE: 'admin/1/course/original',
  CLUB_CURRENT_COURSE: 'admin/1/course/current',
  GET_CLUBS: 'admin/1/clubs',
  SEARCH_CLUBS: 'admin/1/clubs/search',
  GET_CLUB: 'admin/1/club',
  CLUB_INFOEXTRA: 'admin/1/clubinfoextra',
  CLUB_GREEN_CARD: 'admin/1/greenCard',
  CLUB_SERVICE: 'admin/1/clubservice',
  GET_ORGANIZATIONS: 'admin/1/organizations',
  GET_FUNCTIONARIES: 'admin/1/functionaries',
  SEARCH_FUNCTIONARIES: 'admin/1/functionaries/search',
  GET_FUNCTIONARY_TITLES: 'admin/1/functionarytitles',
  FUNCTIONARY_TITLE: 'admin/1/functionary/title',
  FUNCTIONARY: 'admin/1/functionary',
  ORGANIZATION: 'admin/1/organization',
  ORGANIZATIONS: 'admin/1/organizations',
  SEARCH_ORGANIZATIONS: 'admin/1/organizations/search',
  SEARCH_CLUB_MEMBERS: 'admin/1/club/members/search',
  GET_PARTNERS: 'admin/1/partners',
  GET_PARTNER: 'admin/1/partner',
  GET_MAINTENANCES: 'admin/1/maintenances',
  PUBLIC_MAINTENANCES: 'admin/1/maintenances/login',
  GET_MAINTENANCE: 'admin/1/maintenance',
  GET_OFFERS: 'admin/1/offers',
  SEARCH_OFFERS: 'admin/1/offers/search',
  SEARCH_CLUB_SERVICES: 'admin/1/clubservices/search',
  GET_MESSAGES: 'admin/1/messages',
  SEARCH_MESSAGES: 'admin/1/messages/search',
  GET_MESSAGE_SENDERS: 'admin/1/messages/senders',
  GET_MESSAGE_RECIPIENTS: 'admin/1/message/recipients',
  GET_MEMBER: 'admin/1/member',
  USER: 'admin/1/user',
  PARTNER: 'admin/1/partner',
  MAINTENANCE: 'admin/1/maintenance',
  MAINTENANCE_READ: 'admin/1/maintenance-read',
  CLUB: 'admin/1/club',
  OFFER: 'admin/1/offer',
  MESSAGE: 'admin/1/message',
  MESSAGE_IMAGE_UPLOAD: 'admin/1/message/uploadImage',
  OFFER_IMAGE_UPLOAD: 'admin/1/offers/uploadImage',
  GET_CLUBS_CONDENSED: 'client/1/clubs',
  VALIDATE_TOKEN: 'admin/1/validateToken',
  ACTIVATE_ACCOUNT: 'admin/1/activateAccount',
  ADMIN_MEMBERS: 'admin/1/members',
  ADMIN_MEMBERS_SEARCH: 'admin/1/members/search',
  REPORT_FUNCTIONARIES: 'admin/1/reports/functionaries',
  REPORT_MEMBERSHIP: 'admin/1/reports/memberships',
  REPORT_JOIN_DATE: 'admin/1/reports/joinDate',
  REPORT_MEMBERSHIP_STATISTICS: 'admin/1/reports/memberships/statistics',
  REPORT_COURSE_GUIDE: 'admin/1/reports/courseGuide',
  REPORT_GREEN_CARD: 'admin/1/reports/greenCard',
  GET_EBIRDIE_STATISTICS: 'admin/1/reports/ebirdie',
  REPORT_SCORE_STATISTICS: 'admin/1/reports/score',
  REPORT_SCORE_HOLES_STATISTICS: 'admin/1/reports/score/holes',
  REPORT_VENDOR_STATISTICS: 'admin/1/reports/vendor',
  REPORT: 'admin/1/report',
  GET_REPORTS: 'admin/1/report',
  GET_REPORT: 'admin/1/report',
  FEDERATION: 'admin/1/federation',
  GET_FEDERATIONS: 'admin/1/federations',
  GET_PERSONS: 'admin/1/persons',
  SEARCH_PERSONS: 'admin/1/persons/search',
  PERSONS_BULK_COGNITO_STATUS: 'admin/1/persons/bulk-cognito-status',
  PERSON: 'admin/1/person',
  VENDOR: 'admin/1/vendor',
  GET_VENDORS: 'admin/1/vendors',
  GET_COMPANIES: 'admin/1/companies',
  SEARCH_COMPANIES: 'admin/1/companies/search',
  COMPANY: 'admin/1/company',
  HANDICAP: 'admin/1/handicap',
  PROBLEMATIC_PERSONS_SEARCH: 'admin/1/persons/problematic/search',
  REPORTS_CONFIG: 'admin/1/reports/config',
  HIO_EXCEL_IMPORT: 'admin/1/members/insurance/excelImport',
  ADD_ACCEPTABLE_SCORE: 'admin/1/acceptableScore',
  ADD_ACCEPTABLE_SCORE_AWAY: 'admin/1/acceptableScore/away',
  GET_REGIONS: 'admin/1/regions',
  GET_COURSES_LIST: 'admin/1/courses/lists',
  CREATE_COURSES_LIST: 'admin/1/courses/lists',
  acceptableScores: (personId: number) => `admin/1/acceptableScores/${personId}`,
  computedAcceptableScores: (personId: number) => `admin/1/acceptableScores/computed/${personId}`,
  pendingAcceptableScores: (personId: number) => `admin/1/acceptableScores/pending/${personId}`,
  acceptableScoreAdjustment: (scoreId: number, adjustmentId?: number) =>
    adjustmentId
      ? `admin/1/acceptableScore/${scoreId}/adjustment/${adjustmentId}`
      : `admin/1/acceptableScore/${scoreId}/adjustment`,
  acceptableScoreHoles: (scoreId: number) =>
    `admin/1/acceptableScore/${scoreId}/holes`,
  acceptableScoreStatus: (scoreId: number) =>
    `admin/1/acceptableScore/${scoreId}/status`,
  acceptableScorePerson: (scoreId: number) =>
    `admin/1/acceptableScore/${scoreId}/move`,
  handicapInfo: (personId: number) => `admin/1/person/${personId}/handicap`,
  handicapOverride: (personId: number) => `admin/1/person/${personId}/handicap/override`,
  coursePCC: (courseId: number) => `admin/1/course/${courseId}/pcc`,
  setHIOInsurance: (idMembership: number, action: 'enable' | 'disable' | string = 'enable') =>
    `admin/1/members/${idMembership}/insurance/${action}`,
  setCourseStatus: (courseId: number, status: 'activate' | 'deactivate') =>
    `admin/1/course/${courseId}/${status}`,
  /* tslint:disable:max-line-length */
  getHandicapReviewOveriview: (clubId?: number, startDate?: string, endDate?: string, order: 'high' | 'low' = 'high', limit?: number, offset?: number) =>
    `admin/1/report/handicap-review?clubId=${clubId}&startDate=${startDate}&endDate=${endDate}&order=${order}${(limit ? '&limit=' + limit : '')}${(offset ? '&offset=' + offset : '')}`,
  getHandicapReview: (id?: number, startDate?: string, endDate?: string, type?: 'membership' | 'person') =>
    `admin/1/report/handicap-review/${id}?startDate=${startDate}&endDate=${endDate}${(type === 'membership' ? '&type=membership' : '&type=person')}`,
  /* tslint:enable:max-line-length */
  getCourseListContent: (filename) => `admin/1/courses/lists/${filename}/content`,
};

/**
 * Valid App environments.
 */
export type Environment = 'development' | 'qa' | 'production';

/**
 * Returns the name of the App environment.
 */
export const getEnv = (): Environment => {
  const nodeEnv = getEnvVal('NODE_ENV');
  const appEnv = getEnvVal('APP_ENV');
  const reactAppEnv = getEnvVal('REACT_APP_ENV');
  if (nodeEnv === 'qa' || appEnv === 'qa' || reactAppEnv === 'qa') {
    return 'qa';
  }
  if (nodeEnv === 'production' || appEnv === 'production' || reactAppEnv === 'production') {
    return 'production';
  }
  return 'development';
};

/**
 * Returns a boolean indicating if the App environment is production.
 */
export const isProduction: boolean = getEnv() === 'production';

/**
 * Returns a boolean indicating if the App environment is development.
 */
export const isDev: boolean = getEnv() === 'development';

/**
 * Returns the API url.
 *
 * @param env
 */
export const formAPIRoot = (env: Environment = getEnv()): string => {
  const apiUrl = getEnvVal('API_URL');
  if (apiUrl) {
    return apiUrl;
  }
  switch (env) {
    case 'qa':
      return API_URL_QA;
    case 'production':
      return API_URL_PRODUCTION;
    default:
      return process.env.REACT_APP_DEV_API_URL || API_URL_DEVELOPMENT;
  }
};

/**
 * The resolved API URL.
 */
export const API_ROOT: string = formAPIRoot();

export const REPORT_URL: string = getEnvVal('REPORT_URL') ?? '/report/report?idReport=';
// http://localhost:8008/report/report?idReport=';

/**
 * The hostname used by the client for this service
 */
const requestHostname: string = window && window.location && window.location.hostname;

/**
 * Indicates if this site is customized, e.g. not served with local name
 */
export const isCustomContext: boolean = !/^(127\.0\.0\.1|localhost)$/.test(requestHostname);

/*
 * Constants defining available image types. The images need to be imported at images/index.ts.
 */
export const URL_LOGO: string = 'logo';
export const URL_LOGO_2X: string = 'logo2x';
export const URL_HEADER: string = 'header';
export const URL_HEADER_2X: string = 'header2x';
export const URL_SUBHEADER: string = 'subheader';
export const URL_SUBHEADER_2X: string = 'subheader2x';

/**
 * TReturns the URL of a customized image of requested type.
 *
 * Each service context has its own set of customized images, which is determined by this function from the
 * hostname used in the client request.
 *
 * @param type The type of image (one of URL_* constants)
 */
export const formCustomImageURL = (type: string) => {
  if (isCustomContext) {
    switch (type) {
      case URL_LOGO:
        return SGLLogo;
      case URL_LOGO_2X:
        return SGLLogo2x;
      case URL_HEADER:
        return SGLHeader;
      case URL_HEADER_2X:
        return SGLHeader2x;
      case URL_SUBHEADER:
        return SGLSubHeader;
      case URL_SUBHEADER_2X:
        return SGLSubHeader2x;
      default:
        return null;
    }
  }
  switch (type) {
    case URL_LOGO:
      return SGLLogo;
    case URL_LOGO_2X:
      return SGLLogo2x;
    case URL_HEADER:
      return SGLHeader;
    case URL_HEADER_2X:
      return SGLHeader2x;
    case URL_SUBHEADER:
      return SGLSubHeader;
    case URL_SUBHEADER_2X:
      return SGLSubHeader2x;
    default:
      return null;
  }
};

/**
 * Returns default language based on the national part of the request host domain.
 *
 * If the user has not used the service before or changed the language, the DEFAULT_APP_LANGUAGE will be used by
 * the application (locale/reducer.ts).
 */
const defaultAppLanguageCode: () => string = function () {
  if (/\.fi$/.test(requestHostname)) {
    return 'fi-FI';
  } else if (/\.se$/.test(requestHostname)) {
    return 'fi-SV';
  }
  return 'en-US';
};

/**
 * The default App language.
 */
export const DEFAULT_APP_LANGUAGE_CODE = defaultAppLanguageCode();

/*
 * Local storage settings.
 */
export const LOCALSTORAGE_TOKEN_NAME = 'authToken';
export const LOCALSTORAGE_ROUTE_NAME = 'redirectRoutePath';
export const LOCALSTORAGE_REMEMBER_ME_SET = 'rememberMeSet';
export const LOCALSTORAGE_REMEMBER_ME_USER = 'rememberMeUser';
export const LOCAL_STORAGE_LANG_KEY = 'lang';

/*
 * Pagination settings.
 */
export const DEFAULT_PAGINATION_LIMIT = isDev ? 10 : 20;
export const DEFAULT_PAGINATION_PAGE = 1;

/**
 * the default state of reducers.
 */
export const SHARED_DEFAULT_REDUCER_STATE = {
  requesting: false,
  successful: false,
  error: [],
  pagination: {
    page: 1,
    limit: DEFAULT_PAGINATION_LIMIT,
    totalCount: 0,
  },
  searchActive: false,
  targetIdParams: {
    clubId: undefined,
    federationId: undefined,
    associationId: undefined,
  },
};

/**
 * The App identifier for backend.
 */
export const CLIENT_ID = 'com.golfgamebook.uhs.admin';