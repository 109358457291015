import React, { ChangeEvent, Component, Fragment, ReactNode } from 'react';
import { FormattedMessage, FormattedDate, FormattedTime, injectIntl, WrappedComponentProps } from 'react-intl';
import {
  ContentWrap,
  ContentHeader,
  ContentHeaderTitle,
  ContentLoader,
  SearchWrapper, SearchActions
} from '@src/components/layouts/ui';
import {
  Button,
  TableHead,
  TableRow,
  TableBody, Theme, withStyles, FormControl, TextField, WithStyles
} from '@material-ui/core';
import {
  ScrollableTableContainer,
  ResponsiveTable,
  StickyCell,
  InteractiveCell
} from '@src/components/shared/ScrollableTable';
import { Done, Remove } from '@material-ui/icons';
import { connect } from 'react-redux';
import * as messagesActions from '@src/store/messages/actions';
import { Pagination } from '@src/components/pagination/Pagination';
import { DEFAULT_PAGINATION_PAGE } from '@src/assets/config';
import { MessageModal } from '@src/components/modals/MessageModal';
import { MessagePreviewModal } from '@src/components/modals/MessagePreviewModal';
import { confirm } from '@src/components/modals/Confirm';
import * as messageActions from '@src/store/message/actions';
import HeadHelmet from '@src/components/seo/HeadHelmet';
import { DeleteIconButton } from '@src/components/buttons/buttons';
import { isCustomContext, URL_SUBHEADER, URL_SUBHEADER_2X, formCustomImageURL } from '@src/assets/config';
import { RouteComponentProps, withRouter } from 'react-router';
import { handleSearchFieldOnKeyDownEnterSniff, sortByDesc } from '@src/utils/storeUtils';
import createStyles from '@material-ui/core/styles/createStyles';
import { FederationRoleScopeGuard } from '@src/components/access-control/FederationRoleScopeGuard';
import MessageSenderSelect from '@src/components/forms/MessageSenderSelect';
import moment from 'moment';
import LocalizedDateTimePicker from '@src/components/forms/LocalizedDateTimePicker';

interface Props extends WrappedComponentProps, RouteComponentProps, WithStyles {
  messagesReducer: MessagesState;
  classes: {
    topHeader: string;
  };
  fetchMessages: (params: TablePaginationAction) => void;
  changePage: (params: ChangePageAction) => void;
  deleteMessage: (params: MessageDelete) => void;
  searchChanged: (params: MessagesSearch) => void;
  fetchSearch: (params: SearchPaginationAction) => void;
  resetSearch: () => void;
}

interface State {
  messageIdToEdit?: number;
  newMessageModalOpen: boolean;
  previewMessageModalOpen: boolean;
}

const styles = ({ palette }: Theme) => createStyles({
  topHeader: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
  }
});

class MessagesSceneConnected extends Component<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {
      messageIdToEdit: undefined,
      newMessageModalOpen: false,
      previewMessageModalOpen: false
    };
  }

  UNSAFE_componentWillMount() {
    const {
      messagesReducer: {
        requesting,
        successful
      },
      fetchMessages,
    } = this.props;

    if (!requesting && !successful) {
      fetchMessages({
        page: DEFAULT_PAGINATION_PAGE
      });
    }
  }

  render() {
    const { messageIdToEdit, newMessageModalOpen, previewMessageModalOpen } = this.state;
    const {
      messagesReducer: {
        requesting
      },
      classes
    } = this.props;

    return (
      <Fragment>
        <HeadHelmet titleId={'navigation.messages'} />
        <ContentWrap>
          <ContentHeader>
            <div className={classes.topHeader}>
              <div>
                <ContentHeaderTitle><FormattedMessage id={'scenes.messages.sectionTitle'} /></ContentHeaderTitle>
                <Button
                  variant="contained"
                  onClick={() => this.setState({ newMessageModalOpen: true, messageIdToEdit: undefined })}
                >
                  <FormattedMessage id={'scenes.messages.addNewMessageButtonLabel'} />
                </Button>
              </div>
              <div>
                {isCustomContext && (
                  <img
                    src={formCustomImageURL(URL_SUBHEADER)}
                    srcSet={`${formCustomImageURL(URL_SUBHEADER_2X)} 2x`}
                    height={48}
                  />
                )}
              </div>
            </div>

            {this._renderSearch()}
          </ContentHeader>

          {this._renderMessagesTable()}

          <ContentLoader visible={requesting} />

          {this._renderPagination()}

          <MessageModal
            messageId={messageIdToEdit}
            open={newMessageModalOpen}
            onClose={() => {
              this.setState({
                newMessageModalOpen: false,
              });
            }}
          />

          <MessagePreviewModal
            messageId={messageIdToEdit}
            open={previewMessageModalOpen}
            onClose={() => {
              this.setState({
                previewMessageModalOpen: false,
              });
            }}
          />
        </ContentWrap>
      </Fragment>
    );
  }

  private _renderMessagesTable = (): React.ReactNode => {
    const {
      messagesReducer: {
        requesting,
        successful,
        pagedMessages,
        pagination: {
          page
        }
      }
    } = this.props;

    const _renderPublishedStatusIcon = (status: string): React.ReactNode => {
      return status === 'PUBLISHED' ? <Done /> : <Remove />;
    };

    if (!requesting) {
      return (
        <ScrollableTableContainer>
          <ResponsiveTable>
            <TableHead>
              <TableRow>
                <StickyCell><FormattedMessage id={'scenes.messages.table.headers.title'} /></StickyCell>
                <InteractiveCell><FormattedMessage id={'scenes.messages.table.headers.sender'} /></InteractiveCell>
                <InteractiveCell><FormattedMessage id={'scenes.messages.table.headers.publishAt'} /></InteractiveCell>
                <InteractiveCell><FormattedMessage id={'scenes.messages.table.headers.published'} /></InteractiveCell>
                <InteractiveCell><FormattedMessage id={'strings.hasRead'} /></InteractiveCell>
                <InteractiveCell />
                <InteractiveCell />
              </TableRow>
            </TableHead>
            <TableBody>
              {successful && pagedMessages && sortByDesc(Object.keys(pagedMessages[page])).map((key: string) => (
                <TableRow key={pagedMessages[page][key].id}>
                  <StickyCell>{pagedMessages[page][key].title}</StickyCell>
                  <InteractiveCell>{pagedMessages[page][key].sender}</InteractiveCell>
                  <InteractiveCell style={{ whiteSpace: 'nowrap' }} size={'small'}>
                    {pagedMessages[page][key].published != null &&
                      <FormattedDate value={moment.utc(pagedMessages[page][key].published).local(false).toDate()} />
                    }
                    &nbsp;
                    {pagedMessages[page][key].published != null &&
                      <FormattedTime value={moment.utc(pagedMessages[page][key].published).local(false).toDate()} />
                    }
                  </InteractiveCell>
                  <InteractiveCell size={'small'}>
                    {_renderPublishedStatusIcon(pagedMessages[page][key].messageStatus)}
                  </InteractiveCell>
                  <InteractiveCell>{pagedMessages[page][key].hasRead}</InteractiveCell>
                  <InteractiveCell size={'small'}>
                    {this._renderActionBasedOnState(pagedMessages[page][key])}
                  </InteractiveCell>
                  <InteractiveCell size={'small'}>
                    <DeleteIconButton
                      onClick={() => this._handleDelete(pagedMessages[page][key].id, pagedMessages[page][key].title)}
                    />
                  </InteractiveCell>
                </TableRow>
              ))}
            </TableBody>
          </ResponsiveTable>
        </ScrollableTableContainer>
      );
    } else {
      return null;
    }
  }

  private _renderActionBasedOnState = (message: Message) => {
    const isDraft = message.messageStatus === 'DRAFT';

    return (
      <Button
        onClick={() => {
          if (isDraft) {
            this.setState({ messageIdToEdit: message.id, newMessageModalOpen: true });
          } else {
            this.setState({ messageIdToEdit: message.id, previewMessageModalOpen: true });
          }
        }}
      >
        {!isDraft && (<FormattedMessage id={'buttons.show'} />)}
        {isDraft && (<FormattedMessage id={'buttons.edit'} />)}
      </Button>
    );
  }

  private _handlePageChange = (page: number) => {
    const {
      messagesReducer: {
        pagedMessages,
        searchActive,
        search,
      },
      fetchMessages,
      changePage,
      fetchSearch,
    } = this.props;

    /*
      If the page was already fetched we don't need to get it again.
      Just dispatch an action to chage page.
     */
    if (pagedMessages[page]) {
      changePage({ page });
    } else if (searchActive) {
      fetchSearch({ page, params: search });
    } else {
      fetchMessages({ page });
    }
  }

  private _renderPagination = () => {
    /*
      - Shared component of anchors for dispatching new page requests.
      - we know what the current page is from clubsReducer.pagination
      - on each click we dispatch { type: CLUBS_REQUEST_PAGINATED, page: 1..n }
     */
    const {
      messagesReducer: {
        pagination: {
          page,
          limit,
          totalCount
        }
      }
    } = this.props;

    if (totalCount > limit) {
      return <Pagination page={page} limit={limit} totalCount={totalCount} onPageChange={this._handlePageChange} />;
    }

    return null;
  }

  private _handleDelete = (id: number, title: string) => {
    const { deleteMessage, intl } = this.props;
    confirm({
      message: intl.formatMessage({
        id: 'scenes.messages.deleteMessageMessage',
      }, {
        title
      }),
      options: {
        cancelText: intl.formatMessage({ id: 'buttons.cancel' }),
        okText: intl.formatMessage({ id: 'buttons.ok' }),
      }
    }).then((result: any) => {
      if (result) {
        deleteMessage({
          id
        });
      }
    }, () => {
      window.console.log('cancelled');
    });
  }

  private _renderSearch = (): ReactNode => {
    const {
      messagesReducer: {
        requesting,
        searchActive,
        search: {
          title,
          starts,
          ends,
          sender,
        }
      },
      intl
    } = this.props;

    const disableSearch = this._disableSearch();

    return (
      <SearchWrapper>
        <FormControl disabled={requesting} style={{ marginRight: '1em' }}>
          <TextField
            name="title"
            disabled={requesting}
            value={title}
            label={<FormattedMessage id={'scenes.messages.search.textSearch'} />}
            onChange={(e: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
              const { searchChanged } = this.props;
              const {
                currentTarget: {
                  value,
                }
              } = e;

              searchChanged({
                title: value
              });
            }}
            onKeyDown={(e: React.KeyboardEvent<any>) =>
              handleSearchFieldOnKeyDownEnterSniff(e, this._performSearch, disableSearch)
            }
          />
        </FormControl>

        <FederationRoleScopeGuard>
          <FormControl disabled={requesting} style={{ marginRight: '1em' }}>
            <MessageSenderSelect
              disabled={requesting}
              value={sender}
              multi={false}
              onChangeCallback={(value: any) => {
                const { searchChanged } = this.props;
                searchChanged({
                  sender: value,
                });
              }}
            />
          </FormControl>
        </FederationRoleScopeGuard>

        <FormControl disabled={requesting} style={{ marginRight: '1em' }}>
          <LocalizedDateTimePicker
            disabled={requesting}
            value={(starts != null ? moment.utc(starts).local(false).toDate() : null)}
            onChange={(newStartDate: Date) => {
              const { searchChanged } = this.props;
              searchChanged({
                starts: newStartDate != null ? moment(newStartDate).utc(false).toDate() : null
              });
            }}
            emptyLabel={intl.formatMessage({ id: 'scenes.offers.search.startDate' })}
            clearable={true}
          />
        </FormControl>

        <FormControl disabled={requesting} style={{ marginRight: '1em' }}>
          <LocalizedDateTimePicker
            disabled={requesting}
            value={(ends != null ? moment.utc(ends).local(false).toDate() : null)}
            onChange={(newEndDate: Date) => {
              const { searchChanged } = this.props;
              searchChanged({
                ends: newEndDate != null ? moment(newEndDate).utc(false).toDate() : null
              });
            }}
            emptyLabel={intl.formatMessage({ id: 'scenes.offers.search.endsDate' })}
            clearable={true}
          />
        </FormControl>

        <SearchActions
          isSearchActive={searchActive}
          isApiRequestActive={requesting}
          performSearchAction={this._performSearch}
          resetSearchAction={this._resetSearch}
          isSearchDisabled={disableSearch}
        />
      </SearchWrapper>
    );
  }

  private _performSearch = () => {
    const { fetchSearch, messagesReducer: { search } } = this.props;
    fetchSearch({
      page: DEFAULT_PAGINATION_PAGE,
      params: search,
    });
  }

  private _resetSearch = () => {
    const { resetSearch } = this.props;
    resetSearch();
  }

  private _disableSearch = (): boolean => {
    const {
      messagesReducer: {
        requesting,
        search,
      }
    } = this.props;

    return (requesting || (!search.title && !search.sender && !search.starts && !search.ends));
  }
}

const MessagesScene = withRouter(withStyles(styles)(connect((state: any) => ({
  messagesReducer: state.messagesReducer
}), {
  fetchMessages: messagesActions.fetchMessages,
  changePage: messagesActions.changePage,
  deleteMessage: messageActions.deleteMessage,
  searchChanged: messagesActions.searchChanged,
  fetchSearch: messagesActions.fetchSearch,
  resetSearch: messagesActions.resetSearch,
})((injectIntl(MessagesSceneConnected)))));

export {
  MessagesScene,
};
