import {
  PERSONS_REQUESTING,
  PERSONS_SUCCESS,
  PERSONS_ERROR,
  PERSONS_CHANGE_PAGE,
  PERSONS_SEARCH_CHANGED,
  PERSONS_SEARCH_REQUESTING,
  PERSONS_SEARCH_RESET, PERSONS_SET_TARGET_ID_PARAMS
} from './constants';
import { SHARED_DEFAULT_REDUCER_STATE } from '@src/assets/config';
import {
  formDefaultErrorState,
  formDefaultPagedState,
  formDefaultRequestingState, formDefaultTargetIdState,
  fromDefaultPageChangeState,
  optimisticPagedObjectUpdate, searchStateChangedReducerHelper,
} from '@src/utils/storeUtils';
import {
  PERSON_ADD_REQUESTING,
  PERSON_DELETE_REQUESTING,
  PERSON_EDIT_SUCCESS,
} from '@src/store/person/constants';

const initialState: PersonsState = {
  ...SHARED_DEFAULT_REDUCER_STATE,
  pagedPersons: {},
  search: {
    searchTerm: '',
    status: undefined,
    club: undefined,
    problemType: undefined,
    cognitoUsername: undefined,
    cognitoEmail: undefined,
  }
};

export const personsReducer = (state: PersonsState = initialState, action: any): PersonsState => {
  switch (action.type) {
    case PERSONS_SET_TARGET_ID_PARAMS:
      return formDefaultTargetIdState(initialState, state, action);

    case PERSONS_REQUESTING:
      return formDefaultRequestingState(state);

    case PERSONS_SUCCESS:
      return formDefaultPagedState(state, action, 'pagedPersons');

    case PERSONS_CHANGE_PAGE:
      return fromDefaultPageChangeState(state, action);

    case PERSONS_ERROR:
      return formDefaultErrorState(state, action);

    case PERSONS_SEARCH_CHANGED:
      return searchStateChangedReducerHelper(state, action);

    case PERSONS_SEARCH_REQUESTING: {
      let searchActive = state.searchActive;
      let pagedPersons = state.pagedPersons;
      let pagination = state.pagination;

      if (!searchActive) {
        searchActive = true;
        pagedPersons = {};
        pagination.totalCount = 0;
      }

      pagination.page = action.page;

      return {
        ...state,
        pagedPersons,
        pagination,
        searchActive,
        requesting: true
      };
    }

    case PERSONS_SEARCH_RESET:
      return {
        ...initialState,
        requesting: false,
        targetIdParams: state.targetIdParams,
      };

    // todo: PERSON_EDIT_SUCCESS
    case PERSON_EDIT_SUCCESS: {
      const {
        data,
      } = action;
      const page = state.pagination.page;

      const pagedPersons = optimisticPagedObjectUpdate(state.pagedPersons, page, { ...data });

      return {
        ...state,
        pagedPersons,
      };
    }

      case PERSON_ADD_REQUESTING:
      case PERSON_DELETE_REQUESTING: {
        return {
          ...initialState,
          search: state.search,
          pagination: state.pagination,
          targetIdParams: state.targetIdParams,
        };
      }

    default:
      return state;
  }
};