import React, { ErrorInfo, PureComponent, ReactNode } from 'react';
import {
  Drawer,
  AppBar,
  Toolbar,
  Divider,
  FormControl,
  NativeSelect,
  Input,
  Theme,
  IconButton,
  Hidden,
} from '@material-ui/core';
import classNames from 'classnames';
import withStyles from '@material-ui/core/styles/withStyles';
import { GGBOfficeBox } from '@src/assets/images';
import Navigation from '@src/components/navigation/Navigation';
import ProfileMenu from '@src/components/menu/ProfileMenu';
import { NavLink, RouteComponentProps, withRouter } from 'react-router-dom';
import * as authActions from '@src/store/auth/actions';
import { connect } from 'react-redux';
import { WrappedComponentProps, injectIntl } from 'react-intl';
import { GeneralErrorMessage } from '@src/components/layouts/ui';
import { Alert, AlertTitle } from '@material-ui/lab';
import * as maintenanceActions from '@src/store/maintenance/actions';
import MenuIcon from '@material-ui/icons/Menu';

const drawerWidth: number = 230;

const styles = (theme: Theme) => ({
  root: {
    flexGrow: 1,
    display: 'flex',
    flexDirection: 'column',
    [theme.breakpoints.up('md')]: {
      flexDirection: 'row',
    },
  },
  appFrame: {
    zIndex: 1,
    position: 'relative',
    display: 'flex',
    width: '100%',
    minHeight: '100vh',
  },
  appBar: {
    backgroundColor: 'white',
    color: 'black',
    boxShadow: '0px 2px 4px -1px rgba(0,0,0,0.1)',
    width: '100%',
    padding: 0,
    [theme.breakpoints.up('md')]: {
      width: `calc(100% - ${drawerWidth}px)`,
      marginLeft: drawerWidth,
    },
    zIndex: theme.zIndex.drawer + 1,
  },
  menuButton: {
    marginRight: theme.spacing(2),
    [theme.breakpoints.up('md')]: {
      display: 'none',
    },
  },
  appBarContentWrap: {
    display: 'flex',
    flexGrow: 1,
    justifyContent: 'space-between',
    alignItems: 'center',
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column',
      alignItems: 'flex-start',
      width: '100%',
    },
  },
  appBarContentRightCol: {
    display: 'flex',
    [theme.breakpoints.down('sm')]: {
      marginTop: theme.spacing(1),
      alignSelf: 'flex-end',
    },
  },
  logoWrap: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    padding: theme.spacing(2),
    height: '64px',
    boxSizing: 'border-box',
  },
  'appBar-with-drawer': {
    [theme.breakpoints.up('md')]: {
      width: `calc(100% - ${drawerWidth}px)`,
    },
  },
  'appBar-without-drawer': {
    width: '100%'
  },
  'appBar-left': {
    [theme.breakpoints.up('md')]: {
      marginLeft: drawerWidth,
    },
  },
  'appBar-right': {
    marginRight: drawerWidth,
  },
  drawer: {
    [theme.breakpoints.up('md')]: {
      width: drawerWidth,
      flexShrink: 0,
    },
  },
  drawerPaper: {
    width: drawerWidth,
    borderRight: '1px solid rgba(0, 0, 0, 0.12)',
    [theme.breakpoints.down('sm')]: {
      boxShadow: '0 4px 20px 0 rgba(0, 0, 0, 0.14)',
    },
  },
  toolbar: {
    ...theme.mixins.toolbar,
    height: '64px',
    minHeight: '64px',
    [theme.breakpoints.down('sm')]: {
      minHeight: '56px',
      height: '56px'
    }
  },
  content: {
    flexGrow: 1,
    backgroundColor: 'white',
    padding: theme.spacing(2),
    width: '100%',
    boxSizing: 'border-box',
    overflow: 'auto',
    [theme.breakpoints.down('sm')]: {
      padding: theme.spacing(1),
    },
  },
  dropdownSelect: {
    width: '100%',
    maxWidth: '400px',
    [theme.breakpoints.down('sm')]: {
      width: '100%',
      marginBottom: theme.spacing(1),
    },
  },
});

interface OwnProps {
  auth: AuthState;
  maintenances: MaintenancesState;
  locale: AppLocale;
  setActiveState: (activeState: AuthActiveState) => any;
  children?: ReactNode;
  classes: any;
  readMaintenance: (params: MaintenanceDelete) => any;
}

interface State {
  value: string;
  hasError: boolean;
  error?: Error;
  info?: ErrorInfo;
  mobileOpen: boolean;
}

type Props = OwnProps & RouteComponentProps<any> & WrappedComponentProps;

class LayoutConnected extends PureComponent<Props, State> {

  state: State = {
    value: '',
    hasError: false,
    error: undefined,
    info: undefined,
    mobileOpen: false
  };

  handleDrawerToggle = () => {
    this.setState(prevState => ({
      mobileOpen: !prevState.mobileOpen
    }));
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    const {
      auth: {
        activeState
      }
    } = nextProps;

    const activeClubId = this._getActiveClubId(activeState);
    const activePartnerId = this._getActivePartnerId(activeState);

    if (activeClubId) {
      this.setState({
        value: `club_${activeClubId}`,
      });
    } else if (activePartnerId) {
      this.setState({
        value: `partner_${activePartnerId}`
      });
    } else {
      this.setState({
        value: '',
      });
    }
  }

  componentDidCatch(error, info) {
    console.error(error.name + ':' + error.message);
    console.error(info.componentStack);
    this.setState({
      hasError: true,
      error: error,
      info: info
    });
  }

  render() {
    const { hasError, mobileOpen } = this.state;
    const {
      classes,
      children,
    } = this.props;

    const logoWrapper: ReactNode = (
      <div className={classNames(classes.toolbar, classes.logoWrap)}>
        <NavLink
          to={'/'}
        >
          <img src={GGBOfficeBox} height={36} alt="Logo" />
        </NavLink>
      </div>
    );

    const drawer = (
      <>
        {logoWrapper}
        <Divider />
        <Navigation />
      </>
    );

    return (
      <div className={classes.root}>
        <AppBar position="fixed" className={classes.appBar}>
          <Toolbar>
            <IconButton
              color="inherit"
              aria-label="open drawer"
              edge="start"
              onClick={this.handleDrawerToggle}
              className={classes.menuButton}
            >
              <MenuIcon />
            </IconButton>

            <div className={classes.appBarContentWrap}>
              <div>
                {this._maybeRenderClubsPartnersDropdown()}
              </div>

              <div className={classes.appBarContentRightCol}>
                <ProfileMenu />
              </div>
            </div>
          </Toolbar>
          <Divider />
        </AppBar>

        <nav className={classes.drawer}>
          <Hidden mdUp={true} implementation="css">
            <Drawer
              variant="temporary"
              open={mobileOpen}
              onClose={this.handleDrawerToggle}
              classes={{
                paper: classes.drawerPaper,
              }}
              ModalProps={{
                keepMounted: true,
              }}
            >
              {drawer}
            </Drawer>
          </Hidden>

          <Hidden smDown={true} implementation="css">
            <Drawer
              classes={{
                paper: classes.drawerPaper,
              }}
              variant="permanent"
              open={true}
            >
              {drawer}
            </Drawer>
          </Hidden>
        </nav>

        <main className={classes.content}>
          <div className={classes.toolbar} />
          {this._maybeRenderMaintenanceMessages()}
          {!Boolean(hasError) && children}
          {Boolean(hasError) && <GeneralErrorMessage error={this.state.error} info={this.state.info} />}
        </main>
      </div>
    );
  }

  private _maybeRenderMaintenanceMessages = () => {
    const {
      maintenances: {
        allMaintenances
      },
      locale: {
        appLanguage
      }
    } = this.props;

    const render = allMaintenances.map((message) => (
      <Alert
        onClose={() => { this._handleOnMaintenanceRead(message.id); }}
        key={message.id}
        severity={'warning'}
        style={{ margin: '20px 0px' }}
      >
        <AlertTitle>{message[appLanguage.langName].title}</AlertTitle>
        {message[appLanguage.langName].message}
      </Alert>
    ));
    return (allMaintenances.length > 0 && render);
  }

  private _handleOnMaintenanceRead = (id: number) => {
    const { readMaintenance } = this.props;
    readMaintenance({ id });
  }

  private _maybeRenderClubsPartnersDropdown = () => {
    const {
      auth: {
        clubs,
        partners,
      },
      intl,
      classes,
    } = this.props;

    if ((clubs && clubs.length) || (partners && partners.length)) {
      return (
        <FormControl className={classes.dropdownSelect}>
          <NativeSelect
            value={this.state.value}
            onChange={this._handleActiveStateChange}
            input={<Input name="activeStateInput" id="active-state-input" />}
          >
            {(clubs && clubs.length) && (
              <optgroup label={intl.formatMessage({ id: 'navigation.clubs' })}>
                {clubs.sort((a, b) => {
                  return a.name ? a.name.localeCompare(b.name) : -1;
                }).map(club => (
                  <option key={club.id} value={`club_${club.id}`}>{club.name}</option>
                ))}
              </optgroup>
            )}
            {(partners && partners.length) && (
              <optgroup label={intl.formatMessage({ id: 'navigation.partners' })}>
                {partners.map(partner => (
                  <option key={partner.id} value={`partner_${partner.id}`}>{partner.name}</option>
                ))}
              </optgroup>
            )}
          </NativeSelect>
        </FormControl>
      );
    }

    return null;
  }

  private _handleActiveStateChange = (e: any) => {
    const { history, setActiveState, auth: { roleInfo } } = this.props;
    const { currentTarget: { value } } = e;

    const partnerSelected = e.currentTarget.value.indexOf('partner_') !== -1;
    const selectedId = parseInt(value.substr(value.indexOf('_') + 1, value.length), 10);

    window.console.log(setActiveState);

    if (partnerSelected) {
      const { auth: { partners } } = this.props;
      if (partners) {
        const selectedItm = partners.find((partner: AuthUserPartner) => partner.id === selectedId);
        setActiveState({ partner: selectedItm!, roleInfo: roleInfo! });
        history.push(`/admin/partner-account/${selectedId}`);
      }
    } else {
      const { auth: { clubs } } = this.props;
      if (clubs) {
        const selectedItm = clubs.find((club: AuthUserClub) => club.id === selectedId);
        setActiveState({ club: selectedItm!, roleInfo: roleInfo! });
        history.push(`/admin/home-club/${selectedId}`);
      }
    }
  }

  private _getActiveClubId = (activeState?: AuthActiveState): number | undefined => {
    if (activeState && activeState.club) {
      return activeState.club.id;
    }

    return undefined;
  }

  private _getActivePartnerId = (activeState?: AuthActiveState): number | undefined => {
    if (activeState && activeState.partner) {
      return activeState.partner.id;
    }

    return undefined;
  }
}

const Layout = withRouter(injectIntl(connect((state: any) => ({
  auth: state.authReducer,
  maintenances: state.maintenancesReducer,
  locale: state.locale
}), {
  setActiveState: authActions.setActiveState,
  readMaintenance: maintenanceActions.readMaintenance,
})(withStyles(styles as any)(LayoutConnected))));

export default Layout;
