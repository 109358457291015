import React, { PureComponent, ChangeEvent } from 'react';
import { FormattedMessage, WrappedComponentProps, injectIntl } from 'react-intl';
import {
  SearchWrapper,
  SearchActions
} from 'src/components/layouts/ui';
import {
  TextField,
  FormControl,
} from '@material-ui/core';
import { connect } from 'react-redux';
import * as personsActions from '@src/store/persons/actions';
import { DEFAULT_PAGINATION_PAGE } from '@src/assets/config';
import { ClubSelect } from '@src/components/forms/ClubSelect';
import { handleSearchFieldOnKeyDownEnterSniff } from '@src/utils/storeUtils';
import Select from '@src/components/forms/Select';

export const problemTypes = [
  {
    'id': 1,
    'name': 'Irralliset henkilöt (ei jäsenyyttä tai greencardia)'
  },
  {
    'id': 2,
    'name': 'Ei jäsenyyttä'
  },
  {
    'id': 3,
    'name': 'Ei kotiseuraa'
  },
  {
    'id': 4,
    'name': 'Useita kotiseuroja'
  },
  {
    'id': 5,
    'name': 'Ei aktiivinen kotiseura'
  },
  {
    'id': 6,
    'name': 'Henkilön kotiseura ei vastaa jäsenyyksien tietoja'
  },
  {
    'id': 7,
    'name': 'Monistuneet henkilöt'
  },
  {
    'id': 8,
    'name': 'Uudelleenkäytetyt jäsennumerot'
  },
  {
    'id': 9,
    'name': 'Kaikki jäsenyydet',
  }
];

interface Props extends WrappedComponentProps {
  withProblemTypes?: boolean;
  personsReducer: PersonsState;
  searchChanged: (params: PersonsSearch) => any;
  fetchSearch: (params: SearchPaginationAction) => any;
  resetSearch: () => any;
  locale: AppLocale;
  customNode?: React.ReactNode;
}

type State = {
  cognitoUsernameField: string;
  cognitoEmailField: string;
};

class PersonsSearchBar extends PureComponent<Props, State> {
  state: State = {
    cognitoUsernameField: '',
    cognitoEmailField: ''
  };

  render() {
    const {
      personsReducer: {
        requesting,
        search,
        searchActive,
      },
      customNode
    } = this.props;

    const disableSearch = this._disableSearch();

    return (
      <SearchWrapper>
        <TextField
          disabled={requesting}
          label={<FormattedMessage id={'strings.searchTerm'} />}
          value={search.searchTerm}
          style={{ marginRight: '1em' }}
          inputProps={{
            name: 'text-search',
            id: 'text-search',
          }}
          onChange={this._searchTermChanged}
          onKeyDown={(e: React.KeyboardEvent<any>) =>
            handleSearchFieldOnKeyDownEnterSniff(e, this._performSearch, disableSearch)
          }
        />

        {this._maybeRenderClubAndStatus()}
        {this._maybeRenderProblemTypeSelect()}

        <TextField
          disabled={requesting}
          label={<FormattedMessage id={'strings.cognitoUsername'} defaultMessage="Golf-ID käyttäjätunnus" />}
          value={this.state.cognitoUsernameField}
          style={{ marginRight: '1em' }}
          inputProps={{
            name: 'cognito-username-search',
            id: 'cognito-username-search',
          }}
          onChange={this._handleCognitoUsernameChange}
          onKeyDown={(e: React.KeyboardEvent<any>) =>
            handleSearchFieldOnKeyDownEnterSniff(e, this._performSearch, disableSearch)
          }
        />

        <TextField
          disabled={requesting}
          label={<FormattedMessage id={'strings.cognitoEmail'} defaultMessage="Golf-ID sähköposti" />}
          value={this.state.cognitoEmailField}
          style={{ marginRight: '1em' }}
          inputProps={{
            name: 'cognito-email-search',
            id: 'cognito-email-search',
          }}
          onChange={this._handleCognitoEmailChange}
          onKeyDown={(e: React.KeyboardEvent<any>) =>
            handleSearchFieldOnKeyDownEnterSniff(e, this._performSearch, disableSearch)
          }
        />

        <SearchActions
          isSearchActive={searchActive}
          isApiRequestActive={requesting}
          performSearchAction={this._performSearch}
          resetSearchAction={this._resetSearch}
          isSearchDisabled={disableSearch}
        />
        {customNode}
      </SearchWrapper>
    );
  }

  private _maybeRenderProblemTypeSelect = () => {
    const {
      personsReducer: {
        requesting,
        search,
      },
      withProblemTypes = false,
    } = this.props;

    if (!withProblemTypes) {
      return null;
    }

    return (
      <FormControl disabled={requesting}>
        <Select
          disabled={requesting}
          isClearable={false}
          selected={search.problemType || problemTypes[0]}
          options={problemTypes}
          onChangeCallback={(value) => {
            const { searchChanged } = this.props;
            searchChanged({
              problemType: value,
            });
          }}
          labelText={<FormattedMessage id={'strings.problemType'} />}
        />
      </FormControl>
    );
  }

  private _maybeRenderClubAndStatus = () => {
    const {
      personsReducer: {
        requesting,
        search,
        targetIdParams,
      },
      withProblemTypes = false,
    } = this.props;

    if (withProblemTypes) {
      return null;
    }

    return (
      <>
        {/* Club selection is disabled if we are searching inside a club */}
        {Boolean(targetIdParams && !targetIdParams.clubId) && (
          <FormControl disabled={requesting} style={{ marginRight: '1em' }}>
            <ClubSelect
              disabled={requesting}
              selectedClubs={search.club}
              onChangeCallback={(value: any) => {
                const { searchChanged } = this.props;
                searchChanged({
                  club: value
                });
              }}
            />
          </FormControl>
        )}
        <FormControl disabled={requesting}>
          <Select
            selected={search.status}
            options={[
              {
                id: 'INACTIVE',
                name: this.props.intl.formatMessage({ id: 'strings.statusInactive' }),
              },
              {
                id: 'ACTIVE',
                name: this.props.intl.formatMessage({ id: 'strings.statusActive' }),
              }
            ]}
            onChangeCallback={(value) => {
              this._searchStatusChanged(value || '');
            }}
            labelText={<FormattedMessage id={'strings.personState'} />}
            placeholderText={<FormattedMessage id={'strings.personState'} />}
          />
        </FormControl>
      </>
    );
  }

  private _searchTermChanged = (e: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
    const { searchChanged } = this.props;
    const {
      currentTarget: {
        value,
      },
    } = e;

    searchChanged({
      searchTerm: value
    });
  }

  private _searchStatusChanged = (value: any) => {
    const { searchChanged } = this.props;
    const status: ActivityStatus = value ? value : undefined;
    searchChanged({
      status
    });
  }

  private _performSearch = () => {
    const { fetchSearch, personsReducer: { search }, locale: { appLanguage: { collation } } } = this.props;
    fetchSearch({
      page: DEFAULT_PAGINATION_PAGE,
      params: {
        ...search as any,
        collation
      },
    });
  }

  private _resetSearch = () => {
    const {
      resetSearch,
    } = this.props;

    resetSearch();

    this.setState({
      cognitoUsernameField: '',
      cognitoEmailField: ''
    });
  }

  private _handleCognitoUsernameChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    this.setState({ cognitoUsernameField: e.target.value });

    const { searchChanged, personsReducer: { search } } = this.props;
    const searchParams = {
      ...search
    } as PersonsSearch & { cognitoUsername?: string };

    searchParams.cognitoUsername = e.target.value || undefined;
    searchChanged(searchParams);
  }

  private _handleCognitoEmailChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    this.setState({ cognitoEmailField: e.target.value });

    const { searchChanged, personsReducer: { search } } = this.props;
    const searchParams = {
      ...search
    } as PersonsSearch & { cognitoEmail?: string };

    searchParams.cognitoEmail = e.target.value || undefined;
    searchChanged(searchParams);
  }

  private _disableSearch = (): boolean => {
    const {
      personsReducer: {
        requesting,
        search,
      }
    } = this.props;

    const extendedSearch = search as PersonsSearch & {
      cognitoUsername?: string;
      cognitoEmail?: string;
    };

    return (requesting ||
      (!search.searchTerm &&
        !search.status &&
        !search.club &&
        !search.problemType &&
        !extendedSearch.cognitoUsername &&
        !extendedSearch.cognitoEmail));
  }
}

export default injectIntl(connect((state: any) => ({
  personsReducer: state.personsReducer,
  locale: state.locale
}), {
  searchChanged: personsActions.searchChanged,
  fetchSearch: personsActions.fetchSearch,
  resetSearch: personsActions.resetSearch
})(PersonsSearchBar));