import React, { Fragment } from 'react';
import { FormattedMessage, injectIntl, IntlShape } from 'react-intl';
import HeadHelmet from '@src/components/seo/HeadHelmet';
import {
  ButtonLoaderWrap,
  ContentHeader,
  ContentHeaderTitle,
  ContentWrap
} from '@src/components/layouts/ui';
import {
    Button,
    FormControl,
    FormGroup,
    FormControlLabel,
    Switch,
    FormLabel, RadioGroup, Radio,
} from '@material-ui/core';
import * as reportsActions from '@src/store/reports/actions';
import { connect } from 'react-redux';
import { ClubSelect } from '@src/components/forms/ClubSelect';
import Select from '@src/components/forms/Select';
import * as regionActions from '@src/store/regions/actions';
import * as functionariesActions from '@src/store/functionaries/actions';
import { getActiveFederationId } from '@src/store/auth/selectors';
import GenderSelect from '@src/components/forms/ui/GenderSelect';
import LocalizedDatePicker from '@src/components/forms/LocalizedDatePicker';
import { FederationRoleScopeGuard } from '@src/components/access-control/FederationRoleScopeGuard';

export enum YesNoAllEnum {
    ALL = 'ALL',
    YES = 'YES',
    NO  = 'NO',
}

type Props = {
  setTargetIdParams: (params: SetTargetIdParams) => any;
  fetchRegions: () => any;
  functionaryTitlesState: FunctionaryTitlesState;
  regionsState: RegionsState;
  activeFederationId: ReturnType<typeof getActiveFederationId>;
  getFunctionariesReport: (params: GetFunctionariesReport) => any;
  intl: IntlShape;
};

interface State {
  loading: boolean;
  options: {
    nro: boolean;
    clubId: boolean;
    clubAbbreviation: boolean;
    clubName: boolean;
    region: boolean;
    memberNro: boolean;
    name: boolean;
    gender: boolean;
    dateOfBirth: boolean;
    streetAddress: boolean;
    postalCode: boolean;
    city: boolean;
    country: boolean;
    email: boolean;
    phone: boolean;
    homeClub: boolean;
    joinDate: boolean;
    funcJoinDate: boolean;
    funcResignDate: boolean;
    funcStatus: boolean;
    funcId: boolean;
    funcTitle: boolean;
    funcTitlePrecision: boolean;
    funcReceivesClubLetter: boolean;
    funcVisibleGuides: boolean;
  };
  filters: {
    clubs: Club[];
    regions: RegionEntry[];
    titles: FunctionaryOptionType[];
    receivesClubLetter: YesNoAllEnum;
    mandatoryClubMembership: YesNoAllEnum;
    limitGender?: TargetGender[];
    limitBirthdateFrom: Date | null;
    limitBirthdateTo: Date | null;
    dateFrom: Date | null;
    dateTo: Date | null;
  };
}

type FunctionaryOptionType = {label: string; id: number};

class ReportsFunctionariesScene extends React.Component<Props, State> {

  constructor(props: Props) {
      super(props);

      this.state = {
          loading: false,
          options: {
              nro: true,
              clubId: true,
              clubAbbreviation: true,
              clubName: true,
              region: true,
              memberNro: true,
              name: true,
              gender: true,
              dateOfBirth: true,
              streetAddress: true,
              postalCode: true,
              city: true,
              country: true,
              email: true,
              phone: true,
              homeClub: true,
              joinDate: true,
              funcJoinDate: true,
              funcResignDate: true,
              funcStatus: true,
              funcId: true,
              funcTitle: true,
              funcTitlePrecision: true,
              funcReceivesClubLetter: true,
              funcVisibleGuides: true,
          },
          filters: {
              clubs: [],
              regions: [],
              titles: [],
              receivesClubLetter: YesNoAllEnum.ALL,
              mandatoryClubMembership: YesNoAllEnum.ALL,
              limitGender: ['MALE', 'FEMALE', 'NONE'] as TargetGender[],
              limitBirthdateFrom: null,
              limitBirthdateTo: null,
              dateFrom: null, // new Date(new Date().getFullYear(), 0, 1),
              dateTo: null,
          }
      };
  }

  componentDidMount() {
    const {
      functionaryTitlesState,
      regionsState,
      setTargetIdParams,
      fetchRegions,
    } = this.props;

    // Fetch functionary titles if needed
    if (functionaryTitlesState.allTitles.length === 0) {
      setTargetIdParams({
        resetState: true,
        targetIdParams: {
          federationId: this.props.activeFederationId,
        }
      });
    } else {
      console.log(functionaryTitlesState);
    }

    if (regionsState.regions.length === 0) {
      fetchRegions();
    } else {
      console.log(regionsState);
    }
  }

  render() {
    const { intl, regionsState, functionaryTitlesState } = this.props;
    const { loading } = this.state;

    const {
        clubs, regions, titles, mandatoryClubMembership, receivesClubLetter,
        limitGender, limitBirthdateFrom, limitBirthdateTo, dateFrom, dateTo
    } = this.state.filters;

    console.log('rendering...');

    return (
      <Fragment>
        <HeadHelmet titleId={'navigation.reportFunctionaries'} />
        <ContentWrap>
          <ContentHeader>
            <ContentHeaderTitle>
              <FormattedMessage id={'scenes.reports.functionaries.sectionTitle'} />
            </ContentHeaderTitle>
          </ContentHeader>
          <FormGroup>
            <FormControl>
              <FormLabel component="legend"><FormattedMessage id={'scenes.reports.functionaries.columns'} /></FormLabel>
              <FormGroup style={{ maxHeight: 300, marginTop: 15 }}>
                <FormControlLabel
                  control={
                    <Switch
                      color={'primary'}
                      disabled={loading}
                      checked={this.state.options.nro}
                      onChange={this._handleChange('nro')}
                      value="nro"
                    />
                  }
                  label={<FormattedMessage id={'scenes.reports.memberInfo.selections.number'} />}
                />
                <FormControlLabel
                  control={
                    <Switch
                      color={'primary'}
                      disabled={loading}
                      checked={this.state.options.clubId}
                      onChange={this._handleChange('clubId')}
                      value="clubId"
                    />
                  }
                  label={<FormattedMessage id={'scenes.reports.memberInfo.selections.clubId'} />}
                />
                <FormControlLabel
                  control={
                    <Switch
                      color={'primary'}
                      disabled={loading}
                      checked={this.state.options.clubAbbreviation}
                      onChange={this._handleChange('clubAbbreviation')}
                      value="clubAbbreviation"
                    />
                  }
                  label={<FormattedMessage id={'scenes.reports.memberInfo.selections.clubAbbreviation'} />}
                />
                <FormControlLabel
                  control={
                    <Switch
                      color={'primary'}
                      disabled={loading}
                      checked={this.state.options.clubName}
                      onChange={this._handleChange('clubName')}
                      value="clubName"
                    />
                  }
                  label={<FormattedMessage id={'scenes.reports.memberInfo.selections.clubName'} />}
                />
                <FormControlLabel
                  control={
                    <Switch
                      color={'primary'}
                      disabled={loading}
                      checked={this.state.options.region}
                      onChange={this._handleChange('region')}
                      value="region"
                    />
                  }
                  label={<FormattedMessage id={'scenes.reports.memberInfo.selections.region'} />}
                />
                <FormControlLabel
                  control={
                    <Switch
                      color={'primary'}
                      disabled={loading}
                      checked={this.state.options.memberNro}
                      onChange={this._handleChange('memberNro')}
                      value="memberNro"
                    />
                  }
                  label={<FormattedMessage id={'strings.memberNo'} />}
                />
                <FormControlLabel
                  control={
                    <Switch
                      color={'primary'}
                      disabled={loading}
                      checked={this.state.options.joinDate}
                      onChange={this._handleChange('joinDate')}
                      value="joinDate"
                    />
                  }
                  label={<FormattedMessage id={'scenes.reports.memberInfo.selections.joinDate'} />}
                />
                <FormControlLabel
                  control={
                    <Switch
                      color={'primary'}
                      disabled={loading}
                      checked={this.state.options.name}
                      onChange={this._handleChange('name')}
                      value="name"
                    />
                  }
                  label={<FormattedMessage id={'strings.name'} />}
                />
                <FormControlLabel
                  control={
                    <Switch
                      color={'primary'}
                      disabled={loading}
                      checked={this.state.options.gender}
                      onChange={this._handleChange('gender')}
                      value="gender"
                    />
                  }
                  label={<FormattedMessage id={'strings.gender'} />}
                />
                <FormControlLabel
                  control={
                    <Switch
                      color={'primary'}
                      disabled={loading}
                      checked={this.state.options.dateOfBirth}
                      onChange={this._handleChange('dateOfBirth')}
                      value="dateOfBirth"
                    />
                  }
                  label={<FormattedMessage id={'strings.dateOfBirth'} />}
                />
                <FormControlLabel
                  control={
                    <Switch
                      color={'primary'}
                      disabled={loading}
                      checked={this.state.options.streetAddress}
                      onChange={this._handleChange('streetAddress')}
                      value="streetAddress"
                    />
                  }
                  label={<FormattedMessage id={'strings.streetAddress'} />}
                />
                <FormControlLabel
                  control={
                    <Switch
                      color={'primary'}
                      disabled={loading}
                      checked={this.state.options.postalCode}
                      onChange={this._handleChange('postalCode')}
                      value="postalCode"
                    />
                  }
                  label={<FormattedMessage id={'strings.postalCode'} />}
                />
                <FormControlLabel
                  control={
                    <Switch
                      color={'primary'}
                      disabled={loading}
                      checked={this.state.options.city}
                      onChange={this._handleChange('city')}
                      value="city"
                    />
                  }
                  label={<FormattedMessage id={'strings.city'} />}
                />
                <FormControlLabel
                  control={
                    <Switch
                      color={'primary'}
                      disabled={loading}
                      checked={this.state.options.country}
                      onChange={this._handleChange('country')}
                      value="country"
                    />
                  }
                  label={<FormattedMessage id={'strings.country'} />}
                />
                <FormControlLabel
                  control={
                    <Switch
                      color={'primary'}
                      disabled={loading}
                      checked={this.state.options.email}
                      onChange={this._handleChange('email')}
                      value="email"
                    />
                  }
                  label={<FormattedMessage id={'strings.email'} />}
                />
                <FormControlLabel
                  control={
                    <Switch
                      color={'primary'}
                      disabled={loading}
                      checked={this.state.options.phone}
                      onChange={this._handleChange('phone')}
                      value="phone"
                    />
                  }
                  label={<FormattedMessage id={'strings.phone'} />}
                />
                <FormControlLabel
                  control={
                    <Switch
                      color={'primary'}
                      disabled={loading}
                      checked={this.state.options.homeClub}
                      onChange={this._handleChange('homeClub')}
                      value="homeClub"
                    />
                  }
                  label={<FormattedMessage id={'strings.homeClub'} />}
                />
                <FormControlLabel
                    control={
                      <Switch
                          color={'primary'}
                          disabled={loading}
                          checked={this.state.options.funcId}
                          onChange={this._handleChange('funcId')}
                          value="funcId"
                      />
                    }
                    label={<FormattedMessage id={'strings.funcId'} />}
                />
                <FormControlLabel
                    control={
                      <Switch
                          color={'primary'}
                          disabled={loading}
                          checked={this.state.options.funcJoinDate}
                          onChange={this._handleChange('funcJoinDate')}
                          value="funcJoinDate"
                      />
                    }
                    label={<FormattedMessage id={'strings.funcJoinDate'} />}
                />
                <FormControlLabel
                    control={
                      <Switch
                          color={'primary'}
                          disabled={loading}
                          checked={this.state.options.funcResignDate}
                          onChange={this._handleChange('funcResignDate')}
                          value="funcResignDate"
                      />
                    }
                    label={<FormattedMessage id={'strings.funcResignDate'} />}
                />
                <FormControlLabel
                    control={
                      <Switch
                          color={'primary'}
                          disabled={loading}
                          checked={this.state.options.funcStatus}
                          onChange={this._handleChange('funcStatus')}
                          value="funcStatus"
                      />
                    }
                    label={<FormattedMessage id={'strings.funcStatus'} />}
                />
                <FormControlLabel
                    control={
                      <Switch
                          color={'primary'}
                          disabled={loading}
                          checked={this.state.options.funcTitle}
                          onChange={this._handleChange('funcTitle')}
                          value="funcTitle"
                      />
                    }
                    label={<FormattedMessage id={'strings.funcTitle'} />}
                />
                <FormControlLabel
                    control={
                      <Switch
                          color={'primary'}
                          disabled={loading}
                          checked={this.state.options.funcTitlePrecision}
                          onChange={this._handleChange('funcTitlePrecision')}
                          value="funcTitlePrecision"
                      />
                    }
                    label={<FormattedMessage id={'strings.funcTitlePrecision'} />}
                />
                  <FormControlLabel
                      control={
                          <Switch
                              color={'primary'}
                              disabled={loading}
                              checked={this.state.options.funcReceivesClubLetter}
                              onChange={this._handleChange('funcReceivesClubLetter')}
                              value="funcReceivesClubLetter"
                          />
                      }
                      label={<FormattedMessage id={'strings.funcReceivesClubLetter'} />}
                  />
                <FormControlLabel
                    control={
                      <Switch
                          color={'primary'}
                          disabled={loading}
                          checked={this.state.options.funcVisibleGuides}
                          onChange={this._handleChange('funcVisibleGuides')}
                          value="funcVisibleGuides"
                      />
                    }
                    label={<FormattedMessage id={'strings.funcVisibleGuides'} />}
                />
              </FormGroup>
            </FormControl>
          </FormGroup>

          <FormGroup style={{ marginTop: 30 }}>
            <FormLabel component="legend"><FormattedMessage id={'scenes.reports.functionaries.filters'} /></FormLabel>

            <FederationRoleScopeGuard>
            <FormControl>
              <ClubSelect
                  margin={'normal'}
                  multi={true}
                  selectedClubs={clubs}
                  helpText={<FormattedMessage id={'forms.clubSelect.allIfNoneSelectedHelpText'} />}
                  onChangeCallback={(value: any) => {
                      let filters = this.state.filters;
                      filters.clubs = value;
                      this.setState({ 'filters': filters } );
                  }}
              />
            </FormControl>

            <FormControl>
              <Select
                  margin={'normal'}
                  options={regionsState.regions}
                  selected={regions}
                  multiSelect={true}
                  onChangeCallback={(value: any) => {
                      let filters = this.state.filters;
                      filters.regions = value;
                      this.setState({ 'filters': filters } );
                  }}
                  labelText={<FormattedMessage id={'scenes.regions.label'} />}
                  placeholderText={<FormattedMessage id={'scenes.regions.placeholder'} />}
                  helpText={<FormattedMessage id={'scenes.regions.placeholder'} />}
              />
            </FormControl>
            </FederationRoleScopeGuard>
            <FormControl>
              <Select
                  margin={'normal'}
                  options={functionaryTitlesState.allTitles}
                  selected={titles}
                  multiSelect={true}
                  onChangeCallback={(value: any) => {
                      let filters = this.state.filters;
                      filters.titles = value;
                      this.setState({ 'filters': filters } );
                  }}
                  labelText={<FormattedMessage id={'scenes.functionaryTitles.label'} />}
                  placeholderText={<FormattedMessage id={'scenes.functionaryTitles.placeholder'} />}
                  helpText={<FormattedMessage id={'scenes.functionaryTitles.placeholder'} />}
              />
            </FormControl>

              <FormControl style={{width: '100%', marginTop: 20}}>
                  <FormLabel>
                      <FormattedMessage id="scenes.messages.form.mandatoryClubMembership"/>
                  </FormLabel>
                  <RadioGroup
                      row={true}
                      value={mandatoryClubMembership}
                      onChange={(event, value) => {
                          let filters = this.state.filters;
                          filters.mandatoryClubMembership = value as YesNoAllEnum;
                          this.setState({ 'filters': filters } );
                      }}
                  >
                      <FormControlLabel
                          value={YesNoAllEnum.ALL}
                          control={<Radio />}
                          label={intl.formatMessage({id: 'options.all'})}
                      />
                      <FormControlLabel
                          value={YesNoAllEnum.YES}
                          control={<Radio />}
                          label={intl.formatMessage({id: 'options.yes'})}
                      />
                      <FormControlLabel
                          value={YesNoAllEnum.NO}
                          control={<Radio />}
                          label={intl.formatMessage({id: 'options.no'})}
                      />
                  </RadioGroup>
              </FormControl>

              <FormControl style={{width: '100%', marginTop: 20}}>
                  <FormLabel>
                      <FormattedMessage id="scenes.messages.form.receivesClubLetter"/>
                  </FormLabel>
                  <RadioGroup
                      row={true}
                      value={receivesClubLetter}
                      onChange={(event, value) => {
                          let filters = this.state.filters;
                          filters.receivesClubLetter = value as YesNoAllEnum;
                          this.setState({ 'filters': filters } );
                      }}
                  >
                      <FormControlLabel
                          value={YesNoAllEnum.ALL}
                          control={<Radio />}
                          label={intl.formatMessage({id: 'options.all'})}
                      />
                      <FormControlLabel
                          value={YesNoAllEnum.YES}
                          control={<Radio />}
                          label={intl.formatMessage({id: 'options.yes'})}
                      />
                      <FormControlLabel
                          value={YesNoAllEnum.NO}
                          control={<Radio />}
                          label={intl.formatMessage({id: 'options.no'})}
                      />
                  </RadioGroup>
              </FormControl>

              <FormControl style={{width: '100%', marginTop: 20}}>
                  <FormGroup row={true}>

                      <FormControl>
                          <LocalizedDatePicker
                              disabled={loading}
                              style={{ marginRight: 20 }}
                              value={limitBirthdateFrom}
                              disablePast={false}
                              onChange={(newDate: Date) => {
                                  let filters = this.state.filters;
                                  filters.limitBirthdateFrom = newDate;
                                  this.setState({ 'filters': filters } );
                              }}
                              clearable={true}
                              label={this.props.intl.formatMessage({ id: 'strings.limitBirthdateFrom' })}
                          />
                      </FormControl>
                      <FormControl>
                          <LocalizedDatePicker
                              disabled={loading}
                              style={{ marginRight: 20 }}
                              value={limitBirthdateTo}
                              disablePast={false}
                              onChange={(newDate: Date) => {
                                  let filters = this.state.filters;
                                  filters.limitBirthdateTo = newDate;
                                  this.setState({ 'filters': filters } );
                              }}
                              clearable={true}
                              label={this.props.intl.formatMessage({ id: 'strings.limitBirthdateTo' })}
                          />
                      </FormControl>
                  </FormGroup>
              </FormControl>
              <FormControl style={{width: '100%', marginTop: 20}}>
                <GenderSelect
                  initialValue={limitGender}
                  title={<FormattedMessage id={'scenes.messages.form.gender'} />}
                  groupProps={{fullWidth: true}}
                  onChange={(value: any) => {
                      let filters = this.state.filters;
                      filters.limitGender = value;
                      this.setState({ 'filters': filters } );
                  }}
                />
              </FormControl>
              <FormControl style={{width: '100%', marginTop: 20}}>
                  <FormGroup row={true}>
                      <FormControl>
                          <LocalizedDatePicker
                              disabled={loading}
                              style={{ marginRight: 20 }}
                              value={dateFrom}
                              disablePast={false}
                              onChange={(newDate: Date) => {
                                  let filters = this.state.filters;
                                  filters.dateFrom = newDate;
                                  this.setState({ 'filters': filters } );
                              }}
                              clearable={true}
                              label={this.props.intl.formatMessage({ id: 'strings.startDate' })}
                          />
                      </FormControl>
                      <FormControl>
                          <LocalizedDatePicker
                              disabled={loading}
                              style={{ marginRight: 20 }}
                              value={dateTo}
                              disablePast={false}
                              onChange={(newDate: Date) => {
                                  let filters = this.state.filters;
                                  filters.dateTo = newDate;
                                  this.setState({ 'filters': filters } );
                              }}
                              clearable={true}
                              label={this.props.intl.formatMessage({ id: 'strings.endDate' })}
                          />
                      </FormControl>
                  </FormGroup>
              </FormControl>
          </FormGroup>

          <ButtonLoaderWrap loading={loading} style={{ marginTop: 30 }}>
            <Button
              disabled={loading}
              variant="contained"
              color="primary"
              onClick={this._handleLoadOnClick}
            >
              <FormattedMessage id={'buttons.download'} />
            </Button>
          </ButtonLoaderWrap>
        </ContentWrap>
      </Fragment>
    );
  }

  private _handleChange = name => event => {
    const options = {
      ...this.state.options,
      [name]: event.target.checked,
    };

    this.setState({ options });
  }

  private _handleLoadOnClick = (): boolean => {
    const { options } = this.state;
    const activeColumnsCount = Object.values(options).filter(option => option === true).length;
    if (activeColumnsCount === 0) {
      window.alert(this.props.intl.formatMessage({ id: 'strings.noActiveColumns' }));
      return false;
    }
    this.setState({ loading: true }, this._handleSending);
    return true;
  }

  private _handleSending = () => {
    const { getFunctionariesReport } = this.props;
    const { options } = this.state;
    const rawFilters = this.state.filters;
    let filters = {
        clubs: rawFilters.clubs.map(data => data.id),
        regions: rawFilters.regions.map(data => data.name),
        titles: rawFilters.titles.map(data => data.id),
        receivesClubLetter: rawFilters.receivesClubLetter,
        mandatoryClubMembership: rawFilters.mandatoryClubMembership,
        limitGender: rawFilters.limitGender,
        limitBirthdateFrom: rawFilters.limitBirthdateFrom,
        limitBirthdateTo: rawFilters.limitBirthdateTo,
        dateFrom: rawFilters.dateFrom,
        dateTo: rawFilters.dateTo
    };
    const includedFields = Object.keys(options).filter(opt => options[opt]).join(',');

    if (includedFields !== '') {
      getFunctionariesReport({
        includedFields,
        filters,
        onComplete: () => {
          this.setState({ loading: false });
        }
      });
    }
  }
}

export default injectIntl(connect((state: StoreState) => ({
  regionsState: state.regionsReducer,
  functionaryTitlesState: state.functionaryTitlesReducer,
  activeFederationId: getActiveFederationId(state),
}), {
  getFunctionariesReport: reportsActions.getFunctionariesReport,
  setTargetIdParams: functionariesActions.setTargetIdParams,
  fetchRegions: regionActions.fetchRegions,
})(ReportsFunctionariesScene));
