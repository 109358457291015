import React from 'react';
import { useIntl } from 'react-intl';
import { ListItemType, renderListWithGrid } from '@src/components/layouts/ui';
import { formatStatusToStringId } from '@src/utils/storeUtils';

interface Props {
  person?: Person;
}

function PersonalInfo({
  person,
}: Props) {
  const { formatMessage: f, formatDate: fd } = useIntl();

  let listData: ListItemType[] = [];
  if (person) {
    listData = [
      {
        label: 'strings.personId',
        value: person.idPerson,
      },
      {
        label: 'strings.personState',
        value: f({ id: formatStatusToStringId(person.personStatus as any) }),
      },
      {
        label: 'strings.playerState',
        value: person.playerStatus,
      },
      {
        label: 'strings.homeClub',
        value: person.club && person.club.name ? `${person.club.name} (${person.club.abbreviation})` : '-'
      },
      {
        label: 'strings.gender',
        value: person.gender as string,
      },
      {
        label: 'strings.name',
        value: `${person.nameFirst} ${person.nameMiddle} ${person.nameLast}`
      },
      {
        label: 'strings.dateOfBirth',
        value: person.birthDate ? fd(person.birthDate) : '-'
      },
      {
        label: 'strings.address',
        value: person.streetAddress || '-',
      },
      {
        label: 'strings.phone',
        value: person.phone ? person.phone : '-'
      },
      {
        label: 'strings.email',
        value: person.email ? person.email : '-'
      },
      {
        label: 'strings.vendorLastUpdate',
        value: fd(person.vendorUpdated)
      },
      {
        label: 'strings.latestUpdate',
        value: fd(person.timeUpdated),
      },
    ];
  }

  return (
    <>
      {renderListWithGrid(listData, 2)}
    </>
  );
}

export default PersonalInfo;