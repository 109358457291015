import { createMuiTheme } from '@material-ui/core/styles';

// Define breakpoints for responsive design
const breakpoints = {
  values: {
    xs: 0,
    sm: 600,
    md: 960,
    lg: 1280,
    xl: 1920,
  },
};

const materialUITheme = createMuiTheme({
  breakpoints,
  palette: {
    primary: {
      light: '#32a75e',
      main: '#268d51',
      dark: '#166939',
      contrastText: '#fff',
    },
    secondary: {
      light: '#ff7961',
      main: '#f44336',
      dark: '#ba000d',
      contrastText: '#000',
    },
  },
  overrides: {
    MuiDrawer: {
      paper: {
        width: 230,
        [breakpoints.values.md <= 960 ? `@media (max-width: ${breakpoints.values.md}px)` : 'md']: {
          width: 230,
        },
      },
    },
    MuiDialog: {
      paper: {
        zIndex: 1,
        [breakpoints.values.sm <= 600 ? `@media (max-width: ${breakpoints.values.sm}px)` : 'sm']: {
          margin: '16px',
          width: 'calc(100% - 32px)',
          maxWidth: 'calc(100% - 32px)',
        },
      },
    },
    MuiInputBase: {
      root: {
        minWidth: '12em',
        [breakpoints.values.sm <= 600 ? `@media (max-width: ${breakpoints.values.sm}px)` : 'sm']: {
          minWidth: '100%',
        },
      },
    },
    MuiDialogTitle: {
      root: {
        borderBottom: '1px solid rgb(238, 238, 238)',
        [breakpoints.values.sm <= 600 ? `@media (max-width: ${breakpoints.values.sm}px)` : 'sm']: {
          padding: '16px 16px',
        },
      },
    },
    MuiDialogContent: {
      root: {
        padding: '0 24px 24px',
        [breakpoints.values.sm <= 600 ? `@media (max-width: ${breakpoints.values.sm}px)` : 'sm']: {
          padding: '8px 16px 16px',
        },
      },
    },
    MuiDialogActions: {
      root: {
        borderTop: '1px solid rgb(238, 238, 238)',
        padding: '14px 24px',
        margin: 0,
        [breakpoints.values.sm <= 600 ? `@media (max-width: ${breakpoints.values.sm}px)` : 'sm']: {
          padding: '10px 16px',
          flexWrap: 'wrap',
          justifyContent: 'center',
        },
      },
    },
    MuiPickerDTToolbar: {
      toolbar: {
        '@global': {
          'h4': {
            fontSize: 28,
          },
        },
      },
    },
    MuiTypography: {
      root: {
        '@global': {
          'b, strong': {
            fontWeight: 500,
          },
        },
      },
    },
    MuiToolbar: {
      regular: {
        [breakpoints.values.sm <= 600 ? `@media (max-width: ${breakpoints.values.sm}px)` : 'sm']: {
          minHeight: '56px',
        },
      },
    },
    MuiFormControl: {
      root: {
        [breakpoints.values.sm <= 600 ? `@media (max-width: ${breakpoints.values.sm}px)` : 'sm']: {
          width: '100%',
          marginBottom: '16px',
        },
      },
    },
    MuiFormGroup: {
      root: {
        [breakpoints.values.sm <= 600 ? `@media (max-width: ${breakpoints.values.sm}px)` : 'sm']: {
          flexDirection: 'column',
          '& .MuiFormControl-root': {
            marginRight: 0,
          },
        },
      },
    },
    MuiCard: {
      root: {
        [breakpoints.values.sm <= 600 ? `@media (max-width: ${breakpoints.values.sm}px)` : 'sm']: {
          borderRadius: '4px',
        },
      },
    },
    MuiCardContent: {
      root: {
        [breakpoints.values.sm <= 600 ? `@media (max-width: ${breakpoints.values.sm}px)` : 'sm']: {
          padding: '16px 12px',
        },
      },
    },
    MuiButton: {
      root: {
        [breakpoints.values.sm <= 600 ? `@media (max-width: ${breakpoints.values.sm}px)` : 'sm']: {
          margin: '4px',
          padding: '6px 12px',
        },
      },
    },
    MuiTab: {
      root: {
        [breakpoints.values.sm <= 600 ? `@media (max-width: ${breakpoints.values.sm}px)` : 'sm']: {
          padding: '6px 12px',
          minWidth: '72px',
          fontSize: '0.75rem',
        },
      },
    },
  },
});

export default materialUITheme;
