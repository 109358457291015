import React, { Component } from 'react';
import { connect } from 'react-redux';
import { RouteComponentProps, withRouter } from 'react-router';
import withStyles from '@material-ui/core/styles/withStyles';
import { Nav } from '@src/components/navigation/ui';
import { Accordion, AccordionDetails, AccordionSummary } from '@material-ui/core';
import classNames from 'classnames';
import { FormattedMessage } from 'react-intl';
import { NavLink } from 'react-router-dom';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import {
  isAdminRole,
  isClubOrPartnerScope,
  isClubScope,
  isFederationScope,
  isPartnerScope
} from '@src/components/access-control';

type NavLinkItem = {
  to: string;
  className?: string;
  label: React.ReactNode;
  exact?: boolean;
};

const styles = (theme: any) => ({
  root: {
    width: '100%',
  },
  panel: {
    boxShadow: 'none',
    margin: '0 !important',
    '&:before': {
      display: 'none'
    },
    '&.active': {

    }
  },
  panelSummary: {
    padding: 0,
    minHeight: 'initial !important',
    '& > div': {
      margin: '0 !important'
    },
    [theme.breakpoints.down('sm')]: {
      paddingRight: '32px',
      '& .MuiAccordionSummary-expandIcon': {
        padding: '4px',
      }
    }
  },
  panelDetails: {
    display: 'block',
    padding: 0,
    paddingLeft: '1em',
  },
  rootLink: {
    textTransform: 'uppercase',
    fontWeight: 700,
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    width: '100%',
    [theme.breakpoints.down('sm')]: {
      fontSize: '0.9em',
    }
  }
});

const isActivePath = (pathname: string, section: string): boolean => {
  return pathname.match(`/(admin|caddy|club_pro|club_user|club_admin)\/${section}/`) !== null;
};

interface Props extends RouteComponentProps<any> {
  auth: AuthState;
  classes: any;
}

interface State {
  clubDefaultExpanded: boolean;
  federationDefaultExpanded: boolean;
  reportsDefaultExpanded: boolean;
  managementDefaultExpanded: boolean;
  homeClubDefaultExpanded: boolean;
}

class Navigation extends Component<Props, State> {
  constructor(props: Props) {
    super(props);
    const {
      location: {
        pathname
      },
    } = this.props;

    // todo: set for club users and others too, now only admin
    this.state = {
      homeClubDefaultExpanded: isActivePath(pathname, 'home-club'),
      clubDefaultExpanded: isActivePath(pathname, 'club'),
      federationDefaultExpanded: isActivePath(pathname, 'federation'),
      reportsDefaultExpanded: isActivePath(pathname, 'reports'),
      managementDefaultExpanded: isActivePath(pathname, 'management'),
    };
  }

  render() {
    return (
      <Nav>
        {this._renderHomeClubNavigation()}
        {this._renderPartnerNavigation()}
        {this._renderFederationNavigation()}
        {this._renderClubsNavigation()}
        {this._renderPlayersNavigation()}
        {this._renderPartnersNavigation()}
        {this._renderOffersNavigation()}
        {this._renderMessagesNavigation()}
        {this._renderReportsNavigation()}
        {this._renderUserManagementNavigation()}
      </Nav>
    );
  }

  private _renderHomeClubNavigation = () => {
    const {
      auth: {
        activeState,
      },
    } = this.props;

    if (!this._hasActiveClubSelected()) {
      return null;
    }

    const {
      abbreviation,
      id,
    } = activeState!.club!;

    return this._renderWithExtensionPanel({
      urlRootPath: 'home-club',
      defaultExpanded: this.state.homeClubDefaultExpanded,
      summaryLabel: (
        <FormattedMessage
          id={'navigation.homeClub'}
          values={{ abbreviation }}
        />
      ),
      navLinks: [
        {
          to: `/admin/home-club/${id}/`,
          label: <FormattedMessage id={'navigation.golfFederationBasicInfo'} />,
          exact: true,
        },
        {
          to: `/admin/home-club/${id}/members`,
          label: <FormattedMessage id={'navigation.golfClubMembers'} />,
        },
        {
          to: `/admin/home-club/${id}/courses`,
          label: <FormattedMessage id={'navigation.golfClubCourses'} />,
        },
        {
          to: `/admin/home-club/${id}/functionaries`,
          label: <FormattedMessage id={'navigation.functionaries'} />,
        },
        {
          to: `/admin/home-club/${id}/organizations`,
          label: <FormattedMessage id={'navigation.organizations'} />,
        },
        {
          to: `/admin/home-club/${id}/services`,
          label: <FormattedMessage id={'navigation.golfClubServices'} />,
        },
        {
          to: `/admin/home-club/${id}/payments`,
          label: <FormattedMessage id={'navigation.golfClubPayments'} />,
        },
        {
          to: `/admin/home-club/${id}/green-card`,
          label: <FormattedMessage id={'navigation.golfClubGreenCard'} />,
        },
        /*
        {
          to: `/admin/home-club/${id}/offers`,
          label: <FormattedMessage id={'navigation.offers'}/>,
        },
        {
          to: `/admin/home-club/${id}/messages`,
          label: <FormattedMessage id={'navigation.messages'}/>,
        },
        */
      ],
    });
  }

  private _renderPartnerNavigation = () => {
    return null;
  }

  private _renderFederationNavigation = () => {
    if (isPartnerScope(this.props.auth.roleInfo)) {
      return null;
    }

    let navLinks = [
      {
        to: '/admin/federation/basic-info',
        label: <FormattedMessage id={'navigation.golfFederationBasicInfo'} />,
      },
      {
        to: '/admin/federation/functionaries',
        label: <FormattedMessage id={'navigation.functionaries'} />,
      },
      {
        to: '/admin/federation/organizations',
        label: <FormattedMessage id={'navigation.organizations'} />,
      },
    ];

    if (!isClubScope(this.props.auth.roleInfo)) {
      navLinks = navLinks.concat([
        {
          to: '/admin/federation/companies',
          label: <FormattedMessage id={'navigation.golfFederationCompanies'} />,
        },
        {
          to: '/admin/federation/vendors',
          label: <FormattedMessage id={'navigation.golfFederationVendors'} />,
        },
      ]);
    }

    return this._renderWithExtensionPanel({
      urlRootPath: 'federation',
      defaultExpanded: this.state.federationDefaultExpanded,
      summaryLabel: (
        <FormattedMessage id={'navigation.golfFederation'} />
      ),
      navLinks,
    });
  }

  private _renderClubsNavigation = () => {
    const { classes } = this.props;

    if (isClubOrPartnerScope(this.props.auth.roleInfo)) {
      return null;
    }

    return this._renderNavLink({
      to: '/admin/clubs',
      className: classes.rootLink,
      label: <FormattedMessage id={'navigation.clubs'} />,
    });
  }

  private _renderPlayersNavigation = () => {
    const { classes } = this.props;

    if (isPartnerScope(this.props.auth.roleInfo)) {
      return null;
    }

    return this._renderNavLink({
      to: '/admin/members',
      className: classes.rootLink,
      label: <FormattedMessage id={'navigation.members'} />,
    });
  }

  private _renderPartnersNavigation = () => {
    const { classes } = this.props;

    if (isClubOrPartnerScope(this.props.auth.roleInfo) || !isAdminRole(this.props.auth.roleInfo)) {
      return null;
    }

    return this._renderNavLink({
      to: '/admin/partners',
      className: classes.rootLink,
      label: <FormattedMessage id={'navigation.partners'} />,
    });
  }

  private _renderOffersNavigation = () => {
    const { classes } = this.props;

    return this._renderNavLink({
      to: '/admin/offers',
      className: classes.rootLink,
      label: <FormattedMessage id={'navigation.offers'} />,
    });
  }

  private _renderMessagesNavigation = () => {
    const { classes } = this.props;

    if (isPartnerScope(this.props.auth.roleInfo)) {
      return null;
    }

    return this._renderNavLink({
      to: '/admin/messages',
      className: classes.rootLink,
      label: <FormattedMessage id={'navigation.messages'} />,
    });
  }

  private _renderReportsNavigation = () => {
    if (isPartnerScope(this.props.auth.roleInfo)) {
      return null;
    }

    const navLinks = [
      {
        to: '/admin/reports/green-card',
        label: <FormattedMessage id={'navigation.reportGreenCard'} />,
      },
      {
        to: '/admin/reports/member-info',
        label: <FormattedMessage id={'navigation.reportMemberInfo'} />,
      },
      {
        to: '/admin/reports/member-statistics',
        label: <FormattedMessage id={'navigation.reportMemberStatistics'} />,
      },
    ];

    if (isFederationScope(this.props.auth.roleInfo) || isClubScope(this.props.auth.roleInfo)) {
      navLinks.push(
        {
          to: '/admin/reports/eBirdie-statistics',
          label: <FormattedMessage id={'navigation.reportEbirdie'} />,
        },
        {
          to: '/admin/reports/handicap-statistics',
          label: <FormattedMessage id={'navigation.reportHandicapStatistics'} />,
        },
        {
          to: '/admin/reports/handicap-review',
          label: <FormattedMessage id={'navigation.reportHandicapReview'} />,
        },
        {
          to: '/admin/reports/course-list',
          label: <FormattedMessage id={'navigation.reportCourseList'} />,
        }
      );
    }

    if (isFederationScope(this.props.auth.roleInfo)) {
      navLinks.push(
        {
          to: '/admin/reports/engine',
          label: <FormattedMessage id={'navigation.reportEngine'} />,
        },
      );
    }
    navLinks.push(
      {
        to: '/admin/reports/functionaries',
        label: <FormattedMessage id={'navigation.reportFunctionaries'} />,
      }
    );
    return this._renderWithExtensionPanel({
      urlRootPath: 'reports',
      defaultExpanded: this.state.reportsDefaultExpanded,
      summaryLabel: (
        <FormattedMessage id={'navigation.report'} />
      ),
      navLinks,
    });
  }

  private _renderUserManagementNavigation = () => {
    if (!isAdminRole(this.props.auth.roleInfo)) {
      return null;
    }

    return this._renderWithExtensionPanel({
      urlRootPath: 'management',
      defaultExpanded: this.state.managementDefaultExpanded,
      summaryLabel: (
        <FormattedMessage id={'navigation.management'} />
      ),
      navLinks: [
        {
          to: '/admin/management/users',
          label: <FormattedMessage id={'navigation.users'} />,
        },
        {
          to: '/admin/management/maintenances',
          label: <FormattedMessage id={'navigation.maintenances'} />,
        }
      ],
    });
  }

  private _renderWithExtensionPanel = ({
    urlRootPath,
    defaultExpanded,
    summaryLabel,
    navLinks,
  }: {
    urlRootPath: string;
    defaultExpanded: boolean;
    summaryLabel: React.ReactNode;
    navLinks: NavLinkItem[];
  }) => {
    const {
      classes,
      location: {
        pathname,
      },
    } = this.props;

    return (
      <Accordion
        className={[classes.panel, isActivePath(pathname, urlRootPath) ? 'active' : null].join(' ')}
        defaultExpanded={defaultExpanded}
      >
        <AccordionSummary
          className={classNames(classes.panelSummary, classes.rootLink)}
          expandIcon={<ExpandMoreIcon />}
          IconButtonProps={{
            style: { position: 'absolute', right: 0, zIndex: 1 },
          }}
        >
          <span className={'summary-label'}>
            {summaryLabel}
          </span>
        </AccordionSummary>
        <AccordionDetails className={classes.panelDetails}>
          {navLinks ? navLinks.map(this._renderNavLink) : null}
        </AccordionDetails>
      </Accordion>
    );
  }

  private _renderNavLink = ({
    to,
    className,
    label,
    exact = false,
  }: NavLinkItem) => (
    <NavLink
      key={to}
      to={to}
      activeClassName={'active'}
      className={className}
      exact={exact}
    >
      {label}
    </NavLink>
  )

  private _hasActiveClubSelected = (): boolean => {
    const {
      auth: {
        activeState,
      },
    } = this.props;

    return Boolean(activeState && activeState.club);
  }
}

export default withRouter(connect((state: any) => ({
  auth: state.authReducer,
  locale: state.locale,
}))(withStyles(styles as any)(Navigation)));
