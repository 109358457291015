import React from 'react';
import { Tooltip } from '@material-ui/core';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import CancelIcon from '@material-ui/icons/Cancel';
import WarningIcon from '@material-ui/icons/Warning';
import ErrorOutlineIcon from '@material-ui/icons/ErrorOutline';
import NotificationsActiveIcon from '@material-ui/icons/NotificationsActive';

interface CognitoStatusCellProps {
  personId: number;
  cognitoStatus?: {
    hasCognitoUser: boolean;
    userStatus: string | null;
  };
}

const CognitoStatusCell = ({ personId, cognitoStatus }: CognitoStatusCellProps) => {
  const cellStyle = {
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center',
    height: '24px'
  };

  if (!cognitoStatus) {
    return (
      <Tooltip title="Ei tietoja saatavilla">
        <div style={cellStyle}>
          <ErrorOutlineIcon style={{ color: '#757575' }} />
        </div>
      </Tooltip>
    );
  }

  if (!cognitoStatus.hasCognitoUser) {
    return (
      <Tooltip title="Ei Golf-ID:tä">
        <div style={cellStyle}>
          <CancelIcon style={{ color: '#f44336' }} />
        </div>
      </Tooltip>
    );
  }

  switch (cognitoStatus.userStatus) {
    case 'CONFIRMED':
      return (
        <Tooltip title="Golf-ID aktiivinen">
          <div style={cellStyle}>
            <CheckCircleIcon style={{ color: '#4caf50' }} />
          </div>
        </Tooltip>
      );
    case 'RESET_REQUIRED':
    case 'FORCE_CHANGE_PASSWORD':
      return (
        <Tooltip title="Salasanan vaihto vaaditaan">
          <div style={cellStyle}>
            <NotificationsActiveIcon style={{ color: '#f44336' }} />
          </div>
        </Tooltip>
      );
    case 'UNCONFIRMED':
      return (
        <Tooltip title="Golf-ID ei vahvistettu">
          <div style={cellStyle}>
            <WarningIcon style={{ color: '#ff9800' }} />
          </div>
        </Tooltip>
      );
    default:
      return (
        <Tooltip title={`Golf-ID tila: ${cognitoStatus.userStatus}`}>
          <div style={cellStyle}>
            <WarningIcon style={{ color: '#ff9800' }} />
          </div>
        </Tooltip>
      );
  }
};

export default CognitoStatusCell;