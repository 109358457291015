import React, { Component, Fragment } from 'react';
import { FormattedMessage, WrappedComponentProps, injectIntl } from 'react-intl';
import { ContentWrap, ContentHeader, ContentHeaderTitle, ContentLoader } from '@src/components/layouts/ui';
import {
  Button,
  TableHead,
  TableBody,
  TableRow,
} from '@material-ui/core';
import {
  ScrollableTableContainer,
  ResponsiveTable,
  StickyCell,
  InteractiveCell
} from '@src/components/shared/ScrollableTable';
import { connect } from 'react-redux';
import * as maintenancesActions from '@src/store/maintenances/actions';
import * as maintenanceActions from '@src/store/maintenance/actions';
import { DEFAULT_PAGINATION_PAGE } from '@src/assets/config';
import { Pagination } from '@src/components/pagination/Pagination';
import { MaintenanceModal } from '@src/components/modals/MaintenanceModal';
import { confirm } from '@src/components/modals/Confirm';
import HeadHelmet from '@src/components/seo/HeadHelmet';
import { DeleteIconButton, EditIconButton } from '@src/components/buttons/buttons';
import { maintenanceCompare } from '@src/utils/maintenanceUtils';
import moment from 'moment';

interface Props {
  maintenances: MaintenancesState;
  fetchMaintenances: (params: TablePaginationAction) => any;
  changePage: (params: ChangePageAction) => any;
  editMaintenance: (params: MaintenanceEdit) => any;
  addMaintenance: (params: MaintenanceAdd) => any;
  removeMaintenance: (params: MaintenanceDelete) => any;
  locale: AppLocale;
}

interface State {
  isEditMaintenanceDialogOpen?: boolean;
  isNewMaintenanceDialogOpen?: boolean;
  maintenanceIdToEdit?: number;
  modalOpen: boolean;
}

class MaintenancesConnected extends Component<Props & WrappedComponentProps, State> {
  constructor(props: Props & WrappedComponentProps) { 
    super(props);

    this.state = {
      modalOpen: false,
    };
  }

  UNSAFE_componentWillMount() {
    const {
      maintenances: {
        requesting,
        successful
      },
      fetchMaintenances,
      locale: {
        appLanguage: {
          collation
        }
      }
    } = this.props;

    if (!requesting && !successful) {
      fetchMaintenances({
        page: DEFAULT_PAGINATION_PAGE,
        params: { collation }
      });
    }
  }

  render() {
    const { 
      maintenances: {
        requesting
      }
    } = this.props;
    const { maintenanceIdToEdit, modalOpen } = this.state;

    return (
      <Fragment>
        <HeadHelmet titleId={'navigation.maintenances'} />
        <ContentWrap>
          <ContentHeader>
            <ContentHeaderTitle><FormattedMessage id={'scenes.maintenances.sectionTitle'} /></ContentHeaderTitle>
            <Button
                variant="contained"
                onClick={() => this.setState({maintenanceIdToEdit: undefined, modalOpen: true})}
            >
              <FormattedMessage id={'scenes.maintenances.addNewMaintenanceButtonLabel'} />
            </Button>
          </ContentHeader>

          {this._renderMaintenancesTable(this.props)}

          <ContentLoader visible={requesting}/>

          <MaintenanceModal
            maintenanceId={maintenanceIdToEdit}
            open={modalOpen}
            onClose={() => {
              this.setState({
                modalOpen: false,
              });
            }}
          />
        </ContentWrap>
      </Fragment>
    );
  }

  private _formatDateString = (str: string) => {
    return moment.utc(str).local(false).format('DD.MM.YYYY HH:mm');
  }

  private _renderMaintenancesTable = (props: Props) => {
    const {
      maintenances: {
        requesting,
        successful,
        pagedMaintenances,
        pagination: {
          page
        }
      },
      locale: {
        appLanguage
      }
    } = props;

    if (!requesting) {
      // ToDo: Implement error handling
      return (
        <>
          <ScrollableTableContainer>
            <ResponsiveTable>
              <TableHead>
                <TableRow>
                  <StickyCell>
                    <FormattedMessage id={'scenes.maintenances.table.headers.title'} />
                  </StickyCell>
                  <InteractiveCell>
                    <FormattedMessage id={'scenes.maintenances.table.headers.timeFrom'} />
                  </InteractiveCell>
                  <InteractiveCell>
                    <FormattedMessage id={'scenes.maintenances.table.headers.timeUntil'} />
                  </InteractiveCell>
                  <InteractiveCell size={'small'}/>
                  <InteractiveCell size={'small'}/>
                </TableRow>
              </TableHead>
              <TableBody>
                {successful && pagedMaintenances && Object.keys(pagedMaintenances[page]).sort((a, b): number => {
                  return maintenanceCompare(
                      pagedMaintenances[page][a],
                      pagedMaintenances[page][b]
                  );
                }).map((key: string) => (
                  <TableRow key={pagedMaintenances[page][key].id}>
                    <StickyCell>{pagedMaintenances[page][key][appLanguage.langName].title}</StickyCell>
                    <InteractiveCell>{this._formatDateString(pagedMaintenances[page][key].from)}</InteractiveCell>
                    <InteractiveCell>{this._formatDateString(pagedMaintenances[page][key].until)}</InteractiveCell>
                    <InteractiveCell size={'small'}>
                      <EditIconButton
                        onClick={() => this.setState({
                          maintenanceIdToEdit: pagedMaintenances[page][key].id, modalOpen: true
                        })}
                      />
                    </InteractiveCell>
                    <InteractiveCell size={'small'}>
                      <DeleteIconButton
                        onClick={() => {
                          const { id } = pagedMaintenances[page][key];
                          this._handleDestroyClick(id, pagedMaintenances[page][key][appLanguage.langName].title);
                        }}
                      />
                    </InteractiveCell>
                  </TableRow>
                ))}
              </TableBody>
            </ResponsiveTable>
          </ScrollableTableContainer>
          {this._renderPagination()}
        </>
      );
    } else {
      return null;
    }
  }

  private _handleDestroyClick = (id: number, title: string) => {
    const { removeMaintenance, intl } = this.props;
    confirm({
      message: intl.formatMessage({
        id: 'scenes.maintenances.deleteMaintenanceMessage',
      }, {
        title
      }),
      options: {
        cancelText: intl.formatMessage({ id: 'buttons.cancel' }),
        okText: intl.formatMessage({ id: 'buttons.ok' }),
      }
    }).then((result: any) => {
      if (result) {
        removeMaintenance({
          id
        });
      }
    }, () => {
      window.console.log('cancelled');
    });
  }

  private _handlePageChange = (page: number) => {
    const {
      maintenances: {
        pagedMaintenances
      },
      fetchMaintenances,
      changePage,
      locale: {
        appLanguage: {
          collation
        }
      }
    } = this.props;

    if (pagedMaintenances[page]) {
      changePage({ page });
    } else {
      fetchMaintenances({ page, params: { collation } });
    }
  }

  private _renderPagination = () => {
    const {
      maintenances: {
        pagination: {
          page,
          limit,
          totalCount
        }
      }
    } = this.props;

    return <Pagination page={page} limit={limit} totalCount={totalCount} onPageChange={this._handlePageChange}/>;
  }
}

const Maintenances = injectIntl(connect((state: any) => ({
  maintenances: state.maintenancesReducer,
  locale: state.locale
}), {
  fetchMaintenances: maintenancesActions.fetchMaintenances,
  changePage: maintenancesActions.changePage,
  editMaintenance: maintenanceActions.editMaintenance,
  removeMaintenance: maintenanceActions.deleteMaintenance,
  addMaintenance: maintenanceActions.addMaintenance,
})(MaintenancesConnected));

export {
  Maintenances,
};