import React, { ChangeEvent } from 'react';
import { SearchActions, SearchWrapper } from '@src/components/layouts/ui';
import { FormControl, FormGroup, InputLabel, MenuItem, Select, TextField, WithStyles } from '@material-ui/core';
import { FormattedMessage } from 'react-intl';
import { ClubSelect } from '@src/components/forms/ClubSelect';
import { connect } from 'react-redux';
import * as clubActions from '@src/store/club/actions';
import { DEFAULT_PAGINATION_PAGE } from '@src/assets/config';
import withStyles from '@material-ui/core/styles/withStyles';
import { handleSearchFieldOnKeyDownEnterSniff } from '@src/utils/storeUtils';

interface Props extends WithStyles {
  clubId?: number;
  clubState: ClubState;
  searchChanged: (params: ClubMembersSearch) => any;
  fetchSearch: (params: SearchPaginationAction) => any;
  resetSearch: (params: { clubId?: number }) => any;
  locale: AppLocale;
}

class PlayersSearch extends React.Component<Props, {}> {
  render() {
    const {
      clubState: {
        requesting,
        search,
        searchActive
      },
      classes,
      clubId,
      searchChanged,
    } = this.props;

    const isSearchDisabled: boolean = this._disableSearch();

    return (
      <SearchWrapper>
        <div className={classes.innerWrap}>
          <TextField
            disabled={requesting}
            label={<FormattedMessage id={'scenes.golfClub.players.search.textSearch'} />}
            value={search.searchTerm}
            margin="normal"
            style={{ marginRight: '1em' }}
            inputProps={{
              name: 'text-search',
              id: 'text-search',
            }}
            onChange={({ currentTarget }) => {
              const { value } = currentTarget;
              searchChanged({ searchTerm: value });
            }}
            onKeyDown={(e: React.KeyboardEvent<any>) =>
              handleSearchFieldOnKeyDownEnterSniff(e, this._performSearch, isSearchDisabled)
            }
          />

          {/* We only want to show the club select for admin */}
          {Boolean(!clubId) && (
            <FormControl disabled={requesting}>
              <ClubSelect
                margin={'normal'}
                disabled={requesting}
                selectedClubs={search.club}
                onChangeCallback={(value: any) => {
                  searchChanged({
                    club: value
                  });
                }}
              />
            </FormControl>
          )}

          <FormControl>
            <FormGroup row={true}>
              <TextField
                disabled={requesting}
                label={<FormattedMessage id={'scenes.golfClub.players.search.minHandicap'} />}
                value={search.minHandicap}
                type="number"
                margin="normal"
                style={{ marginRight: '1em' }}
                inputProps={{
                  name: 'text-search',
                  id: 'text-search',
                }}
                onChange={({ currentTarget }) => {
                  const { value } = currentTarget;
                  searchChanged({ minHandicap: value as any });
                }}
              />
              <TextField
                disabled={requesting}
                label={<FormattedMessage id={'scenes.golfClub.players.search.maxHandicap'} />}
                value={search.maxHandicap}
                type="number"
                margin="normal"
                style={{ marginRight: '1em' }}
                inputProps={{
                  name: 'text-search',
                  id: 'text-search',
                }}
                onChange={({ currentTarget }) => {
                  const { value } = currentTarget;
                  searchChanged({ maxHandicap: value as any });
                }}
              />
            </FormGroup>
          </FormControl>

          <FormControl>
            <FormGroup row={true}>
              <TextField
                disabled={requesting}
                label={<FormattedMessage id={'scenes.golfClub.players.search.personId'} />}
                value={search.personId}
                margin="normal"
                style={{ marginRight: '1em' }}
                inputProps={{
                  name: 'personid-search',
                  id: 'personid-search',
                }}
                onChange={({ currentTarget }) => {
                  const { value } = currentTarget;
                  searchChanged({ personId: value });
                }}
                onKeyDown={(e: React.KeyboardEvent<any>) =>
                  handleSearchFieldOnKeyDownEnterSniff(e, this._performSearch, isSearchDisabled)
                }
              />
              <TextField
                disabled={requesting}
                label={<FormattedMessage id={'scenes.golfClub.players.search.email'} />}
                value={search.email}
                margin="normal"
                style={{ marginRight: '1em' }}
                inputProps={{
                  name: 'email-search',
                  id: 'email-search',
                }}
                onChange={({ currentTarget }) => {
                  const { value } = currentTarget;
                  searchChanged({ email: value });
                }}
                onKeyDown={(e: React.KeyboardEvent<any>) =>
                  handleSearchFieldOnKeyDownEnterSniff(e, this._performSearch, isSearchDisabled)
                }
              />
            </FormGroup>
          </FormControl>

          <FormGroup row={true}>
            <FormControl disabled={requesting} margin="normal" style={{ marginRight: '1em' }}>
              <InputLabel htmlFor="search-person-status">
                <FormattedMessage id={'scenes.golfClub.players.search.personState'}/>
              </InputLabel>
              <Select
                  inputProps={{
                    name: 'person-status',
                    id: 'search-person-status',
                  }}
                  value={search.personStatus}
                  onChange={({target}: ChangeEvent<HTMLSelectElement>) => {
                    searchChanged({personStatus: target.value as ActivityStatus});
                  }}
              >
                <MenuItem value={'ACTIVE'}><FormattedMessage id={'strings.statusActive'}/></MenuItem>
                <MenuItem value={'INACTIVE'}><FormattedMessage id={'strings.statusPassive'}/></MenuItem>
                <MenuItem value={'ANY'}><FormattedMessage id={'strings.statusAny'}/></MenuItem>
              </Select>
            </FormControl>
            <FormControl disabled={requesting} margin="normal" style={{ marginRight: '1em' }}>
              <InputLabel htmlFor="search-membership-status">
                <FormattedMessage id={'scenes.golfClub.players.search.playerState'}/>
              </InputLabel>
              <Select
                  inputProps={{
                    name: 'membership-status',
                    id: 'search-membership-status',
                  }}
                  value={search.memberStatus}
                  onChange={({target}: ChangeEvent<HTMLSelectElement>) => {
                    searchChanged({memberStatus: target.value as ActivityStatus});
                  }}
              >
                <MenuItem value={'ACTIVE'}><FormattedMessage id={'strings.statusActive'}/></MenuItem>
                <MenuItem value={'INACTIVE'}><FormattedMessage id={'strings.statusPassive'}/></MenuItem>
                <MenuItem value={'ANY'}><FormattedMessage id={'strings.statusAny'}/></MenuItem>
              </Select>
            </FormControl>
            <FormControl  disabled={requesting} margin="normal" style={{ marginRight: '1em' }}>
              <InputLabel htmlFor="search-membership-validity">
                <FormattedMessage id={'scenes.golfClub.players.search.playerValidity'}/>
              </InputLabel>
              <Select
                  inputProps={{
                    name: 'membership-validity',
                    id: 'search-membership-validity',
                  }}
                  value={search.memberValidity || 'ANY'}
                  onChange={({target}: ChangeEvent<HTMLSelectElement>) => {
                    searchChanged({memberValidity: target.value as ValidityStatus});
                  }}
              >
                <MenuItem value={'ANY'}><FormattedMessage id={'strings.validityAny'}/></MenuItem>
                <MenuItem value={'VALID'}><FormattedMessage id={'strings.validityInEffect'}/></MenuItem>
                <MenuItem value={'INVALID'}><FormattedMessage id={'strings.validityExpired'}/></MenuItem>
              </Select>
            </FormControl>
          </FormGroup>
        </div>

        <SearchActions
          isSearchActive={searchActive}
          isApiRequestActive={requesting}
          performSearchAction={this._performSearch}
          resetSearchAction={this._resetSearch}
          isSearchDisabled={isSearchDisabled}
        />
      </SearchWrapper>
    );
  }

  private _performSearch = () => {
    const { fetchSearch, clubState: { search }, clubId, locale: { appLanguage: { collation } } } = this.props;
    fetchSearch({
      page: DEFAULT_PAGINATION_PAGE,
      params: {
        ...search as any,
        clubId,
        collation
      },
    });
  }

  private _resetSearch = () => {
    const { resetSearch, clubId } = this.props;
    resetSearch({ clubId });
  }

  private _disableSearch = (): boolean => {
    const {
      clubState: {
        requesting,
        search: {
          memberValidity,
          memberStatus,
          personStatus,
          club,
          maxHandicap,
          minHandicap,
          searchTerm,
          personId,
          email
          }
      },
    } = this.props;

    return (requesting || ( memberValidity === '' &&
        memberStatus === '' &&
        personStatus === '' &&
        club === undefined &&
        maxHandicap === '' &&
        minHandicap === '' &&
        searchTerm === '' &&
        personId === '' &&
        email === '' ) );
  }
}

export const styles = (theme: any) => ({
  innerWrap: {
    display: 'flex',
    flexDirection: 'column',
    flexGrow: 1,
    maxWidth: '650px',
    marginRight: '30px',
    [theme.breakpoints.down('sm')]: {
      maxWidth: '100%',
      marginRight: 0,
      marginBottom: '20px',
      '& .MuiFormControl-root': {
        marginBottom: theme.spacing(1.5),
        width: '100%',
      },
      '& .MuiInputBase-root': {
        width: '100%',
        minWidth: '0 !important',
      },
      '& .MuiFormGroup-root': {
        marginBottom: 0,
      },
      '& .MuiTextField-root': {
        marginTop: 0,
        marginBottom: theme.spacing(1.5),
      },
    },
  },
});

export default withStyles(styles as any)(connect((state: StoreState) => ({
  clubState: state.clubReducer,
  locale: state.locale
}), {
  fetchClubMembers: clubActions.fetchClubMembers,
  searchChanged: clubActions.searchChanged,
  fetchSearch: clubActions.fetchMembersSearch,
  resetSearch: clubActions.resetSearch,
})(PlayersSearch));
