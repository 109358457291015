import React from 'react';
import { useQuery, useMutation, useQueryClient } from 'react-query';
import { FormattedMessage, useIntl } from 'react-intl';
import {
  Button,
  Typography,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Tooltip,
} from '@material-ui/core';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import CancelIcon from '@material-ui/icons/Cancel';
import WarningIcon from '@material-ui/icons/Warning';
import NotificationsActiveIcon from '@material-ui/icons/NotificationsActive';
import ErrorOutlineIcon from '@material-ui/icons/ErrorOutline';
import useAuthToken from '@src/hooks/useAuthToken';
import { APIRoute, API_ROOT } from '@src/assets/config';
import { connect } from 'react-redux';
import { isFederationAdmin, isClubAdmin } from '@src/components/access-control';

interface OwnProps {
  personId: number;
  cardHeaderClassName?: string;
}

interface CognitoStatusCardProps extends OwnProps {
  roleInfo?: RoleInfo;
}

interface ConfirmDialogProps {
  open: boolean;
  title: string;
  message: string;
  confirmLabel: string;
  onClose: () => void;
  onConfirm: () => void;
  loading?: boolean;
}

const ConfirmDialog = ({
  open,
  title,
  message,
  confirmLabel,
  onClose,
  onConfirm,
  loading = false,
}: ConfirmDialogProps) => (
  <Dialog
    open={open}
    onClose={onClose}
    aria-labelledby="confirm-dialog-title"
    aria-describedby="confirm-dialog-description"
  >
    <DialogTitle id="confirm-dialog-title">{title}</DialogTitle>
    <DialogContent>
      <DialogContentText id="confirm-dialog-description">
        {message}
      </DialogContentText>
    </DialogContent>
    <DialogActions>
      <Button onClick={onClose} color="primary" disabled={loading}>
        <FormattedMessage id="buttons.cancel" />
      </Button>
      <Button
        onClick={onConfirm}
        color="primary"
        variant="contained"
        autoFocus={true}
        disabled={loading}
      >
        {loading ? <CircularProgress size={24} /> : confirmLabel}
      </Button>
    </DialogActions>
  </Dialog>
);

const getUserStatusIcon = (status: string | null) => {
  if (!status) {
    return <CancelIcon />;
  }

  switch (status) {
    case 'CONFIRMED':
      return <CheckCircleIcon />;
    case 'RESET_REQUIRED':
    case 'FORCE_CHANGE_PASSWORD':
      return <NotificationsActiveIcon style={{ color: '#f44336' }} />;
    default:
      return <WarningIcon />;
  }
};

const CognitoStatusCard = ({ personId, cardHeaderClassName = '', roleInfo }: CognitoStatusCardProps) => {
  const isFederationAdminRole = isFederationAdmin(roleInfo);
  const isClubAdminRole = isClubAdmin(roleInfo);
  const token = useAuthToken();
  const queryClient = useQueryClient();
  const intl = useIntl();
  const [dialogState, setDialogState] = React.useState<{
    open: boolean;
    title: string;
    message: string;
    confirmLabel: string;
    action: () => void;
  }>({
    open: false,
    title: '',
    message: '',
    confirmLabel: '',
    action: () => { },
  });
  const [actionLoading, setActionLoading] = React.useState(false);

  const { data, isLoading, isError } = useQuery(
    ['cognitoStatus', personId],
    async () => {
      const response = await fetch(
        `${API_ROOT}${APIRoute.PERSON}/${personId}/cognito-status`,
        {
          method: 'GET',
          headers: {
            'Authorization': `Bearer ${token}`,
          },
        }
      );

      if (!response.ok) {
        throw new Error('Network response was not ok');
      }

      return await response.json();
    },
    {
      refetchOnWindowFocus: false,
      retry: 1,
    }
  );

  const resetPasswordMutation = useMutation(
    async () => {
      setActionLoading(true);
      const response = await fetch(
        `${API_ROOT}${APIRoute.PERSON}/${personId}/reset-cognito-password`,
        {
          method: 'POST',
          headers: {
            'Authorization': `Bearer ${token}`,
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({}),
        }
      );

      if (!response.ok) {
        throw new Error('Network response was not ok');
      }

      return await response.json();
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries(['cognitoStatus', personId]);
        setDialogState(prev => ({ ...prev, open: false }));
        setActionLoading(false);
      },
      onError: () => {
        setActionLoading(false);
      },
    }
  );

  const createAccountMutation = useMutation(
    async () => {
      setActionLoading(true);
      const response = await fetch(
        `${API_ROOT}${APIRoute.PERSON}/${personId}/create-cognito-account`,
        {
          method: 'POST',
          headers: {
            'Authorization': `Bearer ${token}`,
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({}),
        }
      );

      const data = await response.json();

      if (!response.ok || (data && data.success === false)) {
        if (data.message === 'email_already_exists' ||
          (response.status === 409) ||
          (data.message && data.message.includes('already exists'))) {
          throw new Error('email_already_exists');
        }
        throw new Error(data.message || 'Network response was not ok');
      }

      return data;
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries(['cognitoStatus', personId]);
        setDialogState(prev => ({ ...prev, open: false }));
        setActionLoading(false);
      },
      onError: (error: Error) => {
        setActionLoading(false);

        setDialogState({
          open: true,
          title: intl.formatMessage({ id: 'errors.failedGeneric' }),
          message: error.message === 'email_already_exists'
            ? intl.formatMessage({ id: 'scenes.member.errors.emailAlreadyExists' })
            : intl.formatMessage({ id: 'scenes.member.errors.unknownError' }),
          confirmLabel: intl.formatMessage({ id: 'buttons.close' }),
          action: () => setDialogState(prev => ({ ...prev, open: false })),
        });
      },
    }
  );

  const deleteAccountMutation = useMutation(
    async () => {
      setActionLoading(true);
      const response = await fetch(
        `${API_ROOT}${APIRoute.PERSON}/${personId}/delete-cognito-account`,
        {
          method: 'DELETE',
          headers: {
            'Authorization': `Bearer ${token}`,
          },
        }
      );

      if (!response.ok) {
        throw new Error('Network response was not ok');
      }

      return await response.json();
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries(['cognitoStatus', personId]);
        setDialogState(prev => ({ ...prev, open: false }));
        setActionLoading(false);
      },
      onError: () => {
        setActionLoading(false);
      },
    }
  );

  const handleReset = () => {
    setDialogState({
      open: true,
      title: intl.formatMessage({ id: 'scenes.member.dialogs.resetPassword.title' }),
      message: intl.formatMessage({ id: 'scenes.member.dialogs.resetPassword.message' }),
      confirmLabel: intl.formatMessage({ id: 'scenes.member.buttons.resetCognitoPassword' }),
      action: () => resetPasswordMutation.mutate(),
    });
  };

  const handleCreate = () => {
    setDialogState({
      open: true,
      title: intl.formatMessage({ id: 'scenes.member.dialogs.createAccount.title' }),
      message: intl.formatMessage({ id: 'scenes.member.dialogs.createAccount.message' }),
      confirmLabel: intl.formatMessage({ id: 'scenes.member.buttons.createCognitoAccount' }),
      action: () => createAccountMutation.mutate(),
    });
  };

  const handleDelete = () => {
    setDialogState({
      open: true,
      title: intl.formatMessage({ id: 'scenes.member.dialogs.deleteAccount.title' }),
      message: intl.formatMessage({ id: 'scenes.member.dialogs.deleteAccount.message' }),
      confirmLabel: intl.formatMessage({ id: 'scenes.member.buttons.deleteCognitoAccount' }),
      action: () => deleteAccountMutation.mutate(),
    });
  };

  const closeDialog = () => {
    setDialogState(prev => ({ ...prev, open: false }));
  };

  const hasCognitoUser = data?.hasCognitoUser;
  const cognitoUser = data?.cognitoUser;
  const userStatus = cognitoUser?.UserStatus || null;
  const awsError = data?.awsError;

  return (
    <div>
      <div>
        {isLoading ? (
          <div style={{ display: 'flex', justifyContent: 'center', padding: '20px' }}>
            <CircularProgress />
          </div>
        ) : isError || awsError ? (
          <>
            <div style={{ display: 'flex', alignItems: 'center', color: 'red', marginBottom: '16px' }}>
              <ErrorOutlineIcon style={{ marginRight: '8px' }} />
              <Typography variant="body1" color="error">
                {awsError ? (
                  <FormattedMessage
                    id="scenes.member.errors.awsConnectionError"
                    defaultMessage="AWS-yhteysvirhe. Golf-ID tietoja ei voida näyttää täydellisinä."
                  />
                ) : (
                  <FormattedMessage
                    id="scenes.member.errors.loadingError"
                    defaultMessage="Virhe tietojen lataamisessa."
                  />
                )}
              </Typography>
            </div>
            <div style={{ display: 'flex', alignItems: 'center', marginBottom: '10px' }}>
              <div style={{ display: 'flex', alignItems: 'center' }}>
                <ErrorOutlineIcon style={{ color: '#757575' }} />
                <Typography variant="subtitle1" style={{ marginLeft: '8px' }}>
                  <FormattedMessage id="scenes.member.errors.dataNotAvailable" defaultMessage="Tietoja ei saatavilla" />
                </Typography>
              </div>
            </div>
            <div style={{ display: 'flex', justifyContent: 'space-between', marginTop: '16px' }}>
              <Button
                variant="contained"
                color="primary"
                disabled={true}
              >
                <FormattedMessage id="scenes.member.buttons.createCognitoAccount" defaultMessage="Aktivoi Golf-ID" />
              </Button>
            </div>
          </>
        ) : (
          <>
            <div style={{ display: 'flex', alignItems: 'center', marginBottom: '10px' }}>
              <div style={{ display: 'flex', alignItems: 'center' }}>
                {getUserStatusIcon(userStatus)}
                <Typography variant="subtitle1" style={{ marginLeft: '8px' }}>
                  {hasCognitoUser ? (
                    userStatus === 'CONFIRMED' ? (
                      <FormattedMessage
                        id="scenes.member.status.confirmed"
                        defaultMessage="Golf-ID aktiivinen"
                      />
                    ) : userStatus === 'RESET_REQUIRED' || userStatus === 'FORCE_CHANGE_PASSWORD' ? (
                      <Tooltip title={<FormattedMessage id="scenes.member.status.resetRequired.tooltip" defaultMessage="Käyttäjän tulee vaihtaa salasana voidakseen kirjautua sisään" />}>
                        <span>
                          <FormattedMessage
                            id="scenes.member.status.resetRequired"
                            defaultMessage="Salasanan vaihto vaaditaan"
                          />
                        </span>
                      </Tooltip>
                    ) : userStatus === 'UNCONFIRMED' ? (
                      <FormattedMessage
                        id="scenes.member.status.unconfirmed"
                        defaultMessage="Golf-ID ei vahvistettu"
                      />
                    ) : (
                      <FormattedMessage
                        id="scenes.member.status.unknown"
                        defaultMessage="Golf-ID tila: {status}"
                        values={{ status: userStatus }}
                      />
                    )
                  ) : (
                    <FormattedMessage
                      id="scenes.member.status.none"
                      defaultMessage="Ei Golf-ID:tä"
                    />
                  )}
                </Typography>
              </div>
            </div>

            {hasCognitoUser && (
              <>
                {cognitoUser?.UserCreateDate && (
                  <Typography variant="body2" gutterBottom={true}>
                    <FormattedMessage id="scenes.member.labels.cognitoCreated" defaultMessage="Luotu" />:
                    {' '}{new Date(cognitoUser.UserCreateDate).toLocaleString()}
                  </Typography>
                )}

                {cognitoUser?.UserLastModifiedDate && (
                  <Typography variant="body2" gutterBottom={true}>
                    <FormattedMessage id="scenes.member.labels.cognitoModified" defaultMessage="Viimeksi muokattu" />:
                    {' '}{new Date(cognitoUser.UserLastModifiedDate).toLocaleString()}
                  </Typography>
                )}

                {cognitoUser?.Attributes && (
                  <Typography variant="body2" gutterBottom={true}>
                    <FormattedMessage id="scenes.member.labels.cognitoEmail" defaultMessage="Sähköposti" />:
                    {' '}{cognitoUser.Attributes.find((attr: any) => attr.Name === 'email')?.Value}
                  </Typography>
                )}
              </>
            )}

            <div style={{ display: 'flex', justifyContent: 'flex-start', marginTop: '16px', gap: '8px' }}>
              {hasCognitoUser ? (
                <>
                  {isFederationAdminRole && (
                    <Tooltip title={<FormattedMessage id="scenes.member.tooltips.resetPassword" defaultMessage="Lähetä käyttäjälle sähköpostia salasanan vaihtamiseksi" />}>
                      <Button
                        variant="contained"
                        color="primary"
                        onClick={handleReset}
                        size="small"
                      >
                        <FormattedMessage
                          id="scenes.member.buttons.resetCognitoPassword"
                          defaultMessage="Nollaa salasana"
                        />
                      </Button>
                    </Tooltip>
                  )}

                  {isFederationAdminRole && (
                    <Button
                      variant="outlined"
                      color="secondary"
                      onClick={handleDelete}
                      size="small"
                    >
                      <FormattedMessage
                        id="scenes.member.buttons.deleteCognitoAccount"
                        defaultMessage="Poista Golf-ID"
                      />
                    </Button>
                  )}
                </>
              ) : (
                (isFederationAdminRole || isClubAdminRole) && (
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={handleCreate}
                    size="small"
                  >
                    <FormattedMessage
                      id="scenes.member.buttons.createCognitoAccount"
                      defaultMessage="Aktivoi Golf-ID"
                    />
                  </Button>
                )
              )}
            </div>
          </>
        )}
      </div>

      <ConfirmDialog
        open={dialogState.open}
        title={dialogState.title}
        message={dialogState.message}
        confirmLabel={dialogState.confirmLabel}
        onClose={closeDialog}
        onConfirm={dialogState.action}
        loading={actionLoading}
      />
    </div>
  );
};

export default connect<{ roleInfo?: RoleInfo }, {}, OwnProps, StoreState>(state => ({
  roleInfo: state.authReducer.roleInfo,
}))(CognitoStatusCard);