import React, { ChangeEvent, Component, Fragment } from 'react';
import { FormattedMessage, injectIntl, WrappedComponentProps } from 'react-intl';
import {
  Button,
  TableHead,
  TableBody,
  TableRow,
  TextField,
  FormControl,
} from '@material-ui/core';
import {
  ScrollableTableContainer,
  ResponsiveTable,
  StickyCell,
  InteractiveCell
} from '@src/components/shared/ScrollableTable';
import {
  ContentWrap,
  ContentHeader,
  ContentHeaderTitle,
  ContentLoader,
  SearchActions,
  SearchWrapper
} from '@src/components/layouts/ui';
import * as usersActions from '@src/store/users/actions';
import * as userActions from '@src/store/user/actions';
import { connect } from 'react-redux';
import { translateRelativeDate } from '@src/utils/localeUtils';
import { DEFAULT_PAGINATION_PAGE } from '@src/assets/config';
import { Pagination } from '@src/components/pagination/Pagination';
import { UserModal } from '@src/components/modals/UserModal';
import { confirm } from '@src/components/modals/Confirm';
import { ClubSelect } from '@src/components/forms/ClubSelect';
import HeadHelmet from '@src/components/seo/HeadHelmet';
import { handleSearchFieldOnKeyDownEnterSniff } from '@src/utils/storeUtils';
import { DeleteIconButton, EditIconButton } from '@src/components/buttons/buttons';
import { FederationRoleScopeGuard } from '@src/components/access-control/FederationRoleScopeGuard';
import moment from 'moment';
import { userCompare } from '@src/utils/UserUtils';

interface Props {
  auth: AuthState;
  users: UsersState;
  fetchUsers: (params: TablePaginationAction) => any;
  changePage: (params: ChangePageAction) => any;
  searchChanged: (params: UsersSearch) => any;
  fetchSearch: (params: SearchPaginationAction) => any;
  removeUser: (params: UserDelete) => any;
  sendActivationMail: (params: UserSendActivationMail) => any;
  resetSearch: () => any;
  locale: AppLocale;
}

interface State {
  isEditUserDialogOpen: boolean;
  isNewUserDialogOpen: boolean;
  searchText: string;
  selectedClub: string;
  userIdToEdit: number | undefined;
  modalOpen: boolean;
}

class UsersConnected extends Component<Props & WrappedComponentProps, State> {
  constructor(props: Props & WrappedComponentProps) {
    super(props);

    this.state = {
      selectedClub: '',
      searchText: '',
      isEditUserDialogOpen: false,
      isNewUserDialogOpen: false,
      userIdToEdit: undefined,
      modalOpen: false,
    };
  }

  UNSAFE_componentWillMount() {
    const {
      users: {
        successful,
        requesting
      },
      fetchUsers,
      locale: {
        appLanguage: {
          collation
        }
      }
    } = this.props;

    if (!requesting && !successful) {
      fetchUsers({
        page: DEFAULT_PAGINATION_PAGE,
        params: { collation }
      });
    }
  }

  render() {
    const {
      users: {
        requesting
      }
    } = this.props;
    const { userIdToEdit, modalOpen } = this.state;
    return (
      <Fragment>
        <HeadHelmet titleId={'navigation.users'} />
        <ContentWrap>
          <ContentHeader>
            <ContentHeaderTitle><FormattedMessage id={'scenes.users.sectionTitle'} /></ContentHeaderTitle>
            <Button variant="contained" onClick={() => this.setState({userIdToEdit: undefined, modalOpen: true})}>
              <FormattedMessage id={'scenes.users.addNewUserButtonLabel'} />
            </Button>
            {this._renderUserSearch()}
          </ContentHeader>
          <ContentLoader visible={requesting}/>
          {this._renderUsersTable(this.props)}
        </ContentWrap>

        <UserModal
          userId={userIdToEdit}
          open={modalOpen}
          onClose={() => {
            this.setState({
              modalOpen: false,
            });
          }}
        />
      </Fragment>
    );
  }

  private _renderUsersTable = (props: Props): React.ReactNode => {
    const {
      users: {
        requesting,
        successful,
        pagedUsers,
        pagination: {
          page
        }
      },
      auth,
    } = props;

    if (!requesting) {
      // ToDo: Implement error handling
      return (
        <>
          <ScrollableTableContainer>
            <ResponsiveTable size={'small'}>
              <TableHead>
                <TableRow>
                  <StickyCell><FormattedMessage id={'scenes.users.table.headers.name'} /></StickyCell>
                  <InteractiveCell><FormattedMessage id={'scenes.users.table.headers.email'} /></InteractiveCell>
                  <InteractiveCell><FormattedMessage id={'scenes.users.table.headers.lastSeenAt'} /></InteractiveCell>
                  <InteractiveCell />
                  <InteractiveCell />
                  <InteractiveCell />
                </TableRow>
              </TableHead>
              <TableBody>
                {
                  successful &&
                  pagedUsers &&
                  Object.keys(pagedUsers[page]).sort((a, b): number => {
                    return userCompare(
                        pagedUsers[page][a],
                        pagedUsers[page][b],
                        this.props.locale.appLanguage.langName
                    );
                  }).map((key: string) => this._renderUserRow(pagedUsers[page][key], auth))
                }
              </TableBody>
            </ResponsiveTable>
          </ScrollableTableContainer>
          {this._renderPagination()}
        </>
      );
    } else {
      return null;
    }
  }

  private _renderUserRow = (user: User, auth?: AuthState): React.ReactNode => {
    return (
      <TableRow key={user.id}>
        <StickyCell>{user.name}</StickyCell>
        <InteractiveCell>{user.email}</InteractiveCell>
        <InteractiveCell>
          {user.lastSeen
            ? translateRelativeDate(moment.utc(user.lastSeen).local(false).toISOString(false))
            : '-'}
        </InteractiveCell>
        <InteractiveCell>
          {auth && auth.id !== user.id && (
            <EditIconButton
              onClick={() => this.setState({ userIdToEdit: user.id, modalOpen: true })}
            />
          )}
        </InteractiveCell>
        <InteractiveCell>
          {auth && auth.id !== user.id && (
            <Button
              onClick={() =>
                this._handleDeliverUserActivationEmail(user.id, user.email)
              }
            >
              <FormattedMessage id={'buttons.deliveryActivationEmail'}/>
            </Button>
          )}
        </InteractiveCell>
        <InteractiveCell>
          {auth && user.id !== auth.id && (
            <DeleteIconButton
              onClick={() => this._handleDestroyUser(user.id, user.name)}
            />
          )}
        </InteractiveCell>
      </TableRow>
    );
  }

  private _renderUserSearch = (): React.ReactNode => {
    const {
      users: {
        requesting,
        search,
        searchActive
      }
    } = this.props;

    const disableSearch = this._disableSearch();

    return (
      <SearchWrapper>
        <FormControl disabled={requesting}>
          <TextField
            label={<FormattedMessage id={'scenes.users.search.textSearch'} />}
            value={search.searchTerm}
            style={{ marginRight: '1em' }}
            inputProps={{
              name: 'text-search',
              id: 'text-search',
            }}
            onChange={(e: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
              const { searchChanged } = this.props;
              const {
                currentTarget: {
                  value,
                }
              } = e;

              searchChanged({
                searchTerm: value
              });
            }}
            onKeyDown={(e: React.KeyboardEvent<any>) =>
              handleSearchFieldOnKeyDownEnterSniff(e, this._performSearch, disableSearch)
            }
          />
        </FormControl>

        <FederationRoleScopeGuard>
          <FormControl disabled={requesting}>
            <ClubSelect
              selectedClubs={search.club}
              onChangeCallback={(value: any) => {
                const { searchChanged } = this.props;
                searchChanged({
                  club: value
                });
              }}
            />
          </FormControl>
        </FederationRoleScopeGuard>

        <SearchActions
          isSearchActive={searchActive}
          isApiRequestActive={requesting}
          performSearchAction={this._performSearch}
          resetSearchAction={this._resetSearch}
          isSearchDisabled={disableSearch}
        />
      </SearchWrapper>
    );
  }

  private _handleDeliverUserActivationEmail = (id: number, email: string) => {
    const { sendActivationMail, intl } = this.props;
    confirm({
      message: intl.formatMessage({
        id: 'scenes.users.sendActivationMail',
      }, {
        email
      }),
      options: {
        cancelText: intl.formatMessage({ id: 'buttons.cancel' }),
        okText: intl.formatMessage({ id: 'buttons.ok' }),
      }
    }).then((result: any) => {
      if (result) {
        sendActivationMail({
          id
        });
      }
    }, () => {
      window.console.log('cancelled');
    });
  }

  private _handleDestroyUser = (id: number, name: string) => {
    const { removeUser, intl } = this.props;
    confirm({
      message: intl.formatMessage({
        id: 'scenes.users.deleteUserMessage',
      }, {
        name
      }),
      options: {
        cancelText: intl.formatMessage({ id: 'buttons.cancel' }),
        okText: intl.formatMessage({ id: 'buttons.ok' }),
      }
    }).then((result: any) => {
      if (result) {
        removeUser({
          id
        });
      }
    }, () => {
      window.console.log('cancelled');
    });
  }

  private _handlePageChange = (page: number) => {
    const {
      users: {
        pagedUsers,
        searchActive,
        search,
      },
      fetchUsers,
      fetchSearch,
      changePage,
      locale: {
        appLanguage: {
          collation
        }
      }
    } = this.props;

    /*
      If the page was already fetched we don't need to get it again.
      Just dispatch an action to chage page.
     */
    if (pagedUsers[page]) {
      changePage({ page });
    } else if (searchActive) {
      fetchSearch({ page, params: { ...search, collation } });
    } else {
      fetchUsers({ page, params: { collation } });
    }
  }

  private _renderPagination = () => {
    /*
      - Shared component of anchors for dispatching new page requests.
      - we know what the current page is from clubsReducer.pagination
      - on each click we dispatch { type: CLUBS_REQUEST_PAGINATED, page: 1..n }
     */
    const {
      users: {
        pagination: {
          page,
          limit,
          totalCount
        }
      }
    } = this.props;

    if (totalCount > limit) {
      return <Pagination page={page} limit={limit} totalCount={totalCount} onPageChange={this._handlePageChange}/>;
    }

    return null;
  }

  private _performSearch = () => {
    const { fetchSearch, users: { search }, locale: { appLanguage: { collation } } } = this.props;
    fetchSearch({
      page: DEFAULT_PAGINATION_PAGE,
      params: { ...search, collation }
    });
  }

  private _resetSearch = () => {
    const { resetSearch } = this.props;
    resetSearch();
  }

  private _disableSearch = (): boolean => {
    const {
      users: {
        requesting,
        search,
      }
    } = this.props;

    return (requesting || (!search.searchTerm && !search.club));
  }
}

const Users = injectIntl(connect((state: any) => ({
  users: state.usersReducer,
  auth: state.authReducer,
  locale: state.locale
}), {
  fetchUsers: usersActions.fetchUsers,
  changePage: usersActions.changePage,
  searchChanged: usersActions.searchChanged,
  fetchSearch: usersActions.fetchSearch,
  resetSearch: usersActions.resetSearch,
  removeUser: userActions.deleteUser,
  sendActivationMail: userActions.sendActivationMail,
})(UsersConnected));

export {
  Users,
};