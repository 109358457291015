import React, { useEffect, useRef } from 'react';
import styled from 'styled-components';
import { Table, TableCell } from '@material-ui/core';

export const ScrollContainer = styled.div`
  width: 100%;
  overflow-x: auto;
  overflow-y: auto;
  -webkit-overflow-scrolling: touch;
  touch-action: pan-y;
  margin-bottom: 1em;
  position: relative;
  cursor: grab;
  max-height: 80vh;
  
  overscroll-behavior-x: contain;
  scroll-behavior: auto;
  
  @media (max-width: 600px) {
    border: 1px solid rgba(224, 224, 224, 1);
    border-radius: 4px;
    margin-left: -8px;
    margin-right: -8px;
    width: calc(100% + 16px);
    max-width: 100vw;
    max-height: 60vh;
    font-size: 0.8rem;
    
    .MuiTableCell-root {
      font-size: 0.8rem !important;
      padding: 8px !important;
    }
    
    .MuiButtonBase-root.MuiButton-root {
      font-size: 0.8rem !important;
    }
    
    .MuiButton-sizeSmall {
      font-size: 0.7rem !important;
    }
    
    .MuiButtonBase-root.MuiButton-root.MuiButton-text.MuiButton-textPrimary.MuiButton-textSizeSmall.MuiButton-sizeSmall,
    .MuiButtonBase-root.MuiButton-root.MuiButton-text.MuiButton-textPrimary.MuiButton-textSizeSmall.MuiButton-sizeSmall span {
      font-size: 0.7rem !important;
    }
    
    .MuiButton-label {
      font-size: 0.7rem !important;
    }
    
    .MuiButton-root {
      padding: 4px 8px !important;
      min-width: 60px !important;
      line-height: 1 !important;
    }
  }
  
  &.horizontal-scrolling {
    user-select: none;
  }
  
  button, a, .MuiSwitch-root {
    pointer-events: auto;
    user-select: auto !important;
  }
`;

export const TableContainer = styled.div`
  min-width: 700px;
  padding: 1px;
`;

export const ResponsiveTable = styled(Table)`
  table-layout: auto;
  border-collapse: separate;
  border-spacing: 0;
  
  @media (max-width: 600px) {
    th, td {
      font-size: 0.8rem !important;
      padding: 8px !important;
    }
  }
`;

export const StickyCell = styled(TableCell)`
  position: sticky;
  left: 0;
  background-color: white;
  z-index: 2;
  box-shadow: 2px 0 5px -2px rgba(0,0,0,0.1);
  pointer-events: auto;
  max-width: 250px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  
  @media (max-width: 600px) {
    max-width: 150px;
    font-size: 0.8rem !important;
    padding: 8px !important;
  }
`;

export const InteractiveCell = styled(TableCell)`
  pointer-events: auto;
  
  @media (max-width: 600px) {
    font-size: 0.8rem !important;
    padding: 8px !important;
  }
`;

interface ScrollableTableContainerProps {
  children: React.ReactNode;
  id?: string;
}

export const ScrollableTableContainer: React.FC<ScrollableTableContainerProps> = ({
  children,
  id = 'scrollable-table-container'
}) => {
  const containerRef = useRef<HTMLDivElement>(null);

  const setupTableScroll = () => {
    if (typeof document === 'undefined') {
      return;
    }

    const container = containerRef.current;
    if (!container) {
      return;
    }

    let isDown = false;
    let startX: number;
    let scrollLeft: number;
    container.addEventListener('mousedown', (e) => {
      isDown = true;
      container.style.cursor = 'grabbing';
      startX = e.pageX - container.offsetLeft;
      scrollLeft = container.scrollLeft;
    });

    container.addEventListener('mouseleave', () => {
      isDown = false;
      container.style.cursor = 'grab';
    });

    container.addEventListener('mouseup', () => {
      isDown = false;
      container.style.cursor = 'grab';
    });

    container.addEventListener('mousemove', (e) => {
      if (!isDown) {
        return;
      }
      e.preventDefault();
      const x = e.pageX - container.offsetLeft;
      const walk = (x - startX) * 1.5;
      container.scrollLeft = scrollLeft - walk;
    });

    let touchStartX = 0;
    let touchStartY = 0;
    let initialScrollLeft = 0;
    let isHorizontalScroll = false;

    container.addEventListener('touchstart', (e) => {
      if (e.touches.length !== 1) {
        return;
      }

      touchStartX = e.touches[0].pageX;
      touchStartY = e.touches[0].pageY;
      initialScrollLeft = container.scrollLeft;
      isHorizontalScroll = false;
    }, { passive: true });

    container.addEventListener('touchmove', (e) => {
      if (e.touches.length !== 1) {
        return;
      }

      const touchX = e.touches[0].pageX;
      const touchY = e.touches[0].pageY;

      const deltaX = touchStartX - touchX;
      const deltaY = touchStartY - touchY;

      if (!isHorizontalScroll) {
        isHorizontalScroll = Math.abs(deltaX) > Math.abs(deltaY);

        if (isHorizontalScroll) {
          container.classList.add('horizontal-scrolling');
        }
      }

      if (isHorizontalScroll) {
        e.preventDefault();
        container.scrollLeft = initialScrollLeft + deltaX;
      }
    }, { passive: false });

    container.addEventListener('touchend', () => {
      container.classList.remove('horizontal-scrolling');
      isHorizontalScroll = false;
    }, { passive: true });
  };

  const teardownTableScroll = () => {
  };

  useEffect(() => {
    setupTableScroll();
    return () => teardownTableScroll();
  }, []);

  return (
    <ScrollContainer ref={containerRef} className="table-container" id={id}>
      <TableContainer>
        {children}
      </TableContainer>
    </ScrollContainer>
  );
};

export default ScrollableTableContainer;