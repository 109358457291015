import React from 'react';
import { RouteComponentProps, withRouter } from 'react-router';
import { connect } from 'react-redux';
import HeadHelmet from '@src/components/seo/HeadHelmet';
import {
  ContentHeader,
  ContentHeaderTitle,
  ContentLoader,
  ContentWrap,
  TransparentOverlayLoader
} from '@src/components/layouts/ui';
import PersonalInfo from '@src/components/scenes/member/components/PersonalInfo';
import MembershipInfo from '@src/components/scenes/member/components/MembershipInfo';
import Grid from '@material-ui/core/Grid/Grid';
import withStyles, { WithStyles } from '@material-ui/core/styles/withStyles';
import { createStyles, Typography, Tabs, Tab, Button } from '@material-ui/core';
import { FormattedMessage } from 'react-intl';
import CardHeader from '@material-ui/core/CardHeader';
import CardContent from '@material-ui/core/CardContent';
import Card from '@material-ui/core/Card';
import { EditIconFab } from '@src/components/buttons/buttons';
import MembershipInfoModal from '@src/components/scenes/member/components/MembershipInfoModal';
import PersonInfoModal from '@src/components/scenes/member/components/PersonInfoModal';
import { CanEditGuard } from '@src/components/access-control';
import ReactTooltip from 'react-tooltip';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import AcceptableScores from '@src/components/scenes/member/components/AcceptableScores';
import { editPerson, getPerson, resetPersonState } from '@src/store/person/actions';
import { clearMembershipState, getMembership } from '@src/store/membership/actions';
import { get } from 'lodash';
import WHSHandicapInfoCard from '@src/components/scenes/member/components/WHSHandicapInfoCard';
import CognitoStatusCard from '@src/components/scenes/member/components/CognitoStatusCard';
import SwipeableViews from 'react-swipeable-views';
import InvalidatedScores from './components/InvalidatedScores';
import AcceptableScoreModal from '@src/components/scenes/member/components/AcceptableScoreModal';

const styles = () => createStyles({
  root: {
    flexGrow: 1,
  },
  cardHeader: {
    paddingBottom: 0,
  },
  actionContainer: {
    alignItems: 'flex-end',
  },
  swipeableContainer: {
    display: 'block',
    maxWidth: '1156px',
  },
  '@media (min-width: 768px)': {
    swipeableContainer: {
      display: 'grid',
      maxWidth: '100%',
    },
  },
});

interface StateProps {
  personState: PersonState;
  membershipState: MembershipState;
  showLoader: boolean;
}

interface DispatchProps {
  getPerson(params: PersonGet): void;
  editPerson(params: PersonEdit): void;
  getMembership(params: GetMembership): void;
  clearMembershipState(): void;
  resetPersonState(): void;
}

type Props = StateProps & DispatchProps & WithStyles<typeof styles>
  & RouteComponentProps<{ memberId?: string; clubId?: string }>;

type State = {
  editMemberInfoOpen: boolean;
  editPersonInfoOpen: boolean;
  addScoreModalOpen: boolean;
  highlightPerson: boolean;
  highlightMember: boolean;
  slideIndex: number;
  addedScore: boolean;
  resetModalState: boolean;
};

class Member extends React.Component<Props, State> {
  readonly state: State = {
    editMemberInfoOpen: false,
    editPersonInfoOpen: false,
    highlightPerson: false,
    highlightMember: false,
    addScoreModalOpen: false,
    slideIndex: 0,
    addedScore: false,
    resetModalState: true,
  };

  componentWillUnmount(): void {

    this.props.resetPersonState();
    this.props.clearMembershipState();
  }

  async UNSAFE_componentWillMount() {
    const {
      match: { params },
      getPerson,
      getMembership,
      clearMembershipState,
      resetPersonState,
    } = this.props;

    const { memberId, clubId } = params;

    if (clubId && clubId !== '-') {
      getMembership({
        personId: parseInt(`${memberId}`, 10),
        clubId: parseInt(`${clubId}`, 10),
        onComplete: ({ data, error }) => {
          if (!error) {
            const personId = data?.personInfo?.idPerson;
            if (personId) {
              getPerson({ personId });
            }
          } else {
            let personId = parseInt(`${memberId}`, 10);
            getPerson({ personId });
          }
        }
      });
    } else {
      clearMembershipState();
      resetPersonState();
    }
  }

  render() {

    const {
      personState: {
        successful,
        person,
      },
      membershipState: {
        membership,
      },
      showLoader,
    } = this.props;

    const name = this._personName;

    return (
      <>
        {!name && (<HeadHelmet titleId={'navigation.member'} />)}
        {name && (<HeadHelmet titleId={'navigation.memberWithName'} values={{ name }} />)}

        <ContentWrap>

          <TransparentOverlayLoader visible={showLoader} />

          {successful && (
            <>
              <ReactTooltip />
              {this._renderPersonHeader(name)}
              {this._renderContents(person, membership)}
            </>
          )}
        </ContentWrap>
      </>
    );
  }

  get _personName(): string | undefined {
    const person = get(this.props, 'personState.person');
    if (person) {
      return `${person.nameFirst} ${person.nameLast}`;
    }

    return undefined;
  }

  _renderPersonHeader = (name?: string) => {
    if (!name) {
      return null;
    }

    return (
      <ContentHeader>
        <ContentHeaderTitle>
          {name}
        </ContentHeaderTitle>
      </ContentHeader>
    );
  }

  _renderContents = (person?: Person, membership?: Membership) => {
    const {
      classes,
      personState,
      membershipState,
    } = this.props;

    return (
      <>
        <Grid container={true} spacing={5}>
          <Grid item={true} xs={12} sm={6}>
            <Card>
              <CardHeader
                className={classes.cardHeader}
                title={(
                  <>
                    <CopyToClipboard text={personState.person ? personState.person.idPerson : '?'}>
                      <Typography
                        gutterBottom={true}
                        variant="h5"
                        color={this.state.highlightPerson ? 'primary' : undefined}
                        onClick={() => this.setState({ highlightPerson: true })}
                        onMouseLeave={() => this.setState({ highlightPerson: false })}
                      >
                        <a data-tip={`personId: ${personState.person ? personState.person.idPerson : '?'}`}>
                          <FormattedMessage
                            id={'scenes.member.titles.personInfo'}
                          />
                        </a>
                      </Typography>
                    </CopyToClipboard>
                  </>
                )}
                action={(
                  personState.person ? (
                    <CanEditGuard permissions={personState.person._permissions}>
                      <EditIconFab onClick={() => this.setState({ editPersonInfoOpen: true })} />
                    </CanEditGuard>
                  ) : null
                )}
              />
              <CardContent>
                <ContentLoader visible={personState.requesting} />
                <PersonalInfo person={person} />

                {person && person.idPerson && (
                  <div style={{ marginTop: '20px' }}>
                    <CognitoStatusCard personId={person.idPerson} />
                  </div>
                )}
              </CardContent>
            </Card>
          </Grid>
          <Grid item={true} xs={12} sm={6}>
            <Card>
              <CardHeader
                className={classes.cardHeader}
                title={(
                  <CopyToClipboard text={membershipState.membership ? membershipState.membership.idMembership : '?'}>
                    <Typography
                      gutterBottom={true}
                      variant="h5"
                      color={this.state.highlightMember ? 'primary' : undefined}
                      onClick={() => this.setState({ highlightMember: true })}
                      onMouseLeave={() => this.setState({ highlightMember: false })}
                    >
                      <a
                        data-tip={membershipState.membership ? (
                          `memberId: ${membershipState.membership.idMembership} ` +
                          `(clubdId: ${membershipState.membership.clubInfo.idClub}` +
                          `, memberNo: ${membershipState.membership.memberNo})`
                        ) : '?'}
                      >
                        <FormattedMessage id={'scenes.member.titles.memberInfo'} />
                      </a>
                    </Typography>
                  </CopyToClipboard>
                )}
                action={(
                  membershipState.membership ? (
                    <CanEditGuard permissions={membershipState.membership._permissions}>
                      <EditIconFab onClick={() => this.setState({ editMemberInfoOpen: true })} />
                    </CanEditGuard>
                  ) : null
                )}
              />
              <CardContent>
                <ContentLoader visible={membershipState.requesting} />
                <MembershipInfo membership={membership} />
              </CardContent>
            </Card>
          </Grid>

          <Grid item={true} sm={12}>
            <WHSHandicapInfoCard cardHeaderClassName={classes.cardHeader} />
          </Grid>

          {this._personId && (

            <Grid item={true} sm={12}>
              <Card>
                <CardContent>
                  <Grid item={true} xs={12} sm={6}>

                    {this.state.addedScore &&
                      <Button
                        style={{ marginRight: '20px', }}
                        variant="contained"
                        onClick={() => this.setState({ addScoreModalOpen: true, resetModalState: false })}
                      >
                        <FormattedMessage id={'scenes.golfClub.score.addNewWithCourseButtonLabel'} />
                      </Button>
                    }
                    <Button
                      variant="contained"
                      onClick={() => this.setState({
                        addScoreModalOpen: true, addedScore: false, resetModalState: true
                      })}
                    >
                      <FormattedMessage id={'scenes.golfClub.score.addNewButtonLabel'} />
                    </Button>

                  </Grid>
                  <Tabs
                    onChange={this._handleSectionChange}
                    value={this.state.slideIndex}
                    indicatorColor={'primary'}
                    variant={'fullWidth'}
                  >
                    <Tab
                      label={<FormattedMessage id={'strings.acceptableScores'} />}
                      value={0}
                      style={{ minWidth: '0' }}
                    />
                    <Tab
                      label={<FormattedMessage id={'strings.invalidatedScores'} />}
                      value={1}
                      style={{ minWidth: '0' }}
                    />
                  </Tabs>
                  <div className={classes.swipeableContainer}>
                    <SwipeableViews
                      index={this.state.slideIndex}
                      onChangeIndex={this._handleSectionChange}
                    >
                      <AcceptableScores personId={this._personId} showTitle={false} />
                      <InvalidatedScores personId={this._personId} showTitle={false} />
                    </SwipeableViews>
                  </div>
                </CardContent>
              </Card>
            </Grid>
          )}
        </Grid>

        <PersonInfoModal
          open={this.state.editPersonInfoOpen}
          onClose={this.setState.bind(this, { editPersonInfoOpen: false })}
        />

        <MembershipInfoModal
          open={this.state.editMemberInfoOpen}
          onClose={this.setState.bind(this, { editMemberInfoOpen: false })}
        />

        <AcceptableScoreModal
          open={this.state.addScoreModalOpen}
          resetState={this.state.resetModalState}
          onClose={this.setState.bind(this, { addScoreModalOpen: false })}
          onComplete={this.setState.bind(this, { addedScore: true, resetModalState: false })}
        />
      </>
    );
  }

  get _personId() {
    return get(this.props, 'personState.person.idPerson');
  }

  get _clubId() {
    return get(this.props, 'match.params.clubId');
  }

  private _handleSectionChange = (event, value: number) => {
    this.setState({
      slideIndex: value,
    });
  }
}

export default connect<StateProps, DispatchProps, {}, StoreState>(state => ({
  personState: state.personReducer,
  membershipState: state.membershipReducer,
  showLoader: state.acceptableScoreReducer.requesting
    || state.personReducer.requesting
    || state.membershipReducer.requesting,
}), {
  editPerson, getMembership, clearMembershipState, getPerson,
  resetPersonState,
})(withStyles(styles)(withRouter(Member)));