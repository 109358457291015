import React, { PureComponent, ReactNode, Fragment, ChangeEvent } from 'react';
import { FormattedMessage, WrappedComponentProps, injectIntl } from 'react-intl';
import {
  TableHead,
  TableBody,
  TableRow,
  Button,
  FormControl,
  TextField,
} from '@material-ui/core';
import {
  ScrollableTableContainer,
  ResponsiveTable,
  StickyCell,
  InteractiveCell
} from '@src/components/shared/ScrollableTable';
import Select from '@src/components/forms/Select';
import HeadHelmet from '@src/components/seo/HeadHelmet';
import {
  ContentHeader,
  ContentHeaderTitle,
  ContentWrap,
  SearchActions,
  ContentLoader,
  SearchWrapper
} from '@src/components/layouts/ui';
import { FunctionaryModal } from '@src/components/scenes/federation/functionary/FunctionaryModal';
import { connect } from 'react-redux';
import * as functionariesActions from '@src/store/functionaries/actions';
import { DEFAULT_PAGINATION_PAGE } from '@src/assets/config';
import { Pagination } from '@src/components/pagination/Pagination';
import { RouteComponentProps, withRouter } from 'react-router';
import { FunctionaryObject, handleSearchFieldOnKeyDownEnterSniff, targetIdParamsChanged } from '@src/utils/storeUtils';
import { Link } from 'react-router-dom';
import { EditIconButton } from '@src/components/buttons/buttons';
import RoleScopeGuard from '@src/components/access-control/RoleScopeGuard';
import { functionaryCompare } from '@src/utils/FunctionaryUtils';

interface Props extends WrappedComponentProps {
  targetIdParams: TargetIdParams;
  functionariesState: FunctionariesState;
  fetchFunctionaries: (params: TablePaginationAction) => any;
  changePage: (params: ChangePageAction) => any;
  searchChanged: (params: FunctionariesSearch) => any;
  fetchSearch: (params: SearchPaginationAction) => any;
  resetSearch: () => any;
  setTargetIdParams: (params: SetTargetIdParams) => any;
  locale: AppLocale;
}

type State = {
  modalOpen: boolean;
  functionaryIdToEdit?: number;
};

class Functionaries extends PureComponent<Props & RouteComponentProps<any>, State> {
  state = {
    modalOpen: false,
    addNewModalOpen: false,
    functionaryIdToEdit: undefined
  };

  UNSAFE_componentWillMount() {
    const {
      functionariesState: {
        requesting,
        successful,
        targetIdParams,
      },
      locale: {
        appLanguage: {
          collation
        }
      },
      fetchFunctionaries,
      setTargetIdParams,
    } = this.props;

    if (targetIdParamsChanged(targetIdParams, this.props.targetIdParams)) {
      setTargetIdParams({
        resetState: true,
        targetIdParams: this.props.targetIdParams,
      });
      fetchFunctionaries({
        page: DEFAULT_PAGINATION_PAGE,
        params: { collation }
      });
    } else if (!requesting && !successful) {
      fetchFunctionaries({
        page: DEFAULT_PAGINATION_PAGE,
        params: { collation }
      });
    }
  }

  render() {
    const {
      functionariesState: {
        requesting
      },
      match: {
        url
      },
      targetIdParams: {
        clubId,
      },
    } = this.props;
    const {
      modalOpen,
      functionaryIdToEdit
    } = this.state;

    return (
      <Fragment>
        <HeadHelmet titleId={'navigation.functionaries'} />
        <ContentWrap>
          <ContentHeader>
            <ContentHeaderTitle>
              <FormattedMessage id={'navigation.functionaries'} />
            </ContentHeaderTitle>
            <RoleScopeGuard>
              {({ isFederationScope, isClubScope }) => {
                const canAdd = Boolean((isClubScope && clubId) || isFederationScope);
                return canAdd && (
                  <div>
                    <Button
                      variant="contained"
                      to={`${url}/add-functionary`}
                      component={Link}
                    >
                      <FormattedMessage id={'scenes.functionaries.addFunctionaryButtonLabel'} />
                    </Button>
                    <Button
                      variant="contained"
                      style={{ marginLeft: 20 }}
                      to={`${url}/titles`}
                      component={Link}
                    >
                      <FormattedMessage id={'navigation.functionaryTitles'} />
                    </Button>
                  </div>
                );
              }}
            </RoleScopeGuard>

            {this._renderFunctionariesSearch()}
          </ContentHeader>

          <ContentLoader visible={requesting} />

          {this._renderFunctionaries()}

          <FunctionaryModal
            functionaryId={functionaryIdToEdit}
            open={modalOpen}
            onClose={() => {
              this.setState({
                modalOpen: false,
              });
            }}
            targetIdParams={this.props.targetIdParams}
          />
        </ContentWrap>
      </Fragment>
    );
  }

  private _renderFunctionariesSearch = (): ReactNode => {
    const {
      functionariesState: {
        requesting,
        search,
        searchActive
      },
    } = this.props;

    const disableSearch: boolean = this._disableSearch();

    return (
      <SearchWrapper>
        <TextField
          disabled={requesting}
          label={<FormattedMessage id={'scenes.functionaries.search.textSearch'} />}
          value={search.searchTerm}
          style={{ marginRight: '1em' }}
          onChange={this._searchTermChanged}
          onKeyDown={(e: React.KeyboardEvent<any>) =>
            handleSearchFieldOnKeyDownEnterSniff(e, this._performSearch, disableSearch)
          }
        />
        <FormControl disabled={requesting}>
          <Select
            disabled={requesting}
            selected={search.status}
            options={[
              {
                id: 'INACTIVE',
                name: this.props.intl.formatMessage({ id: 'strings.statusInactive' }),
              },
              {
                id: 'ACTIVE',
                name: this.props.intl.formatMessage({ id: 'strings.statusActive' }),
              }
            ]}
            onChangeCallback={(value) => {
              this._searchStatusChanged(value || '');
            }}
            labelText={<FormattedMessage id={'strings.functionaryState'} />}
            placeholderText={<FormattedMessage id={'strings.functionaryState'} />}
          />
        </FormControl>
        <SearchActions
          isSearchActive={searchActive}
          isApiRequestActive={requesting}
          performSearchAction={this._performSearch}
          resetSearchAction={this._resetSearch}
          isSearchDisabled={disableSearch}
        />
      </SearchWrapper>
    );
  }

  private _performSearch = () => {
    const {
      fetchSearch,
      functionariesState: {
        search,
      },
      locale: {
        appLanguage: {
          collation
        }
      }
    } = this.props;

    fetchSearch({
      page: DEFAULT_PAGINATION_PAGE,
      params: { ...search, collation }
    });
  }

  private _resetSearch = () => {
    const { resetSearch } = this.props;
    resetSearch();
  }

  private _disableSearch = (): boolean => {
    const {
      functionariesState: {
        requesting,
        search: {
          searchTerm,
          status,
          // functionaryTitleId
        }
      }
    } = this.props;

    return (requesting || (searchTerm === '' && status === '' /* && functionaryTitleId === '' */));
  }

  private _searchTermChanged = (e: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
    const { searchChanged } = this.props;
    const {
      currentTarget: {
        value,
      }
    } = e;

    searchChanged({
      searchTerm: value
    });
  }

  private _searchStatusChanged = (value: any) => {
    const { searchChanged } = this.props;
    const status: ActivityStatus = value ? value : undefined;
    searchChanged({
      status
    });
  }

  private _renderFunctionaries = (): ReactNode => {
    const {
      functionariesState: {
        requesting,
        successful,
        pagedFunctionaries,
        pagination: {
          page
        }
      }
    } = this.props;

    if (requesting) {
      return null;
    }

    return (
      <>
        <ScrollableTableContainer id="functionaries-table-container">
          <ResponsiveTable size="small">
            <TableHead>
              <TableRow>
                <StickyCell align="left">
                  <FormattedMessage id={'scenes.functionaries.table.headers.title'} />
                </StickyCell>
                <InteractiveCell align="right">
                  <FormattedMessage id={'scenes.functionaries.table.headers.name'} />
                </InteractiveCell>
                <InteractiveCell align="right">
                  <FormattedMessage id={'scenes.functionaries.table.headers.phone'} />
                </InteractiveCell>
                <InteractiveCell align="right">
                  <FormattedMessage id={'scenes.functionaries.table.headers.email'} />
                </InteractiveCell>
                <InteractiveCell align="right">
                  <FormattedMessage id={'scenes.functionaries.table.headers.state'} />
                </InteractiveCell>
                <InteractiveCell align="right" />
              </TableRow>
            </TableHead>
            <TableBody>
              {
                successful
                && pagedFunctionaries[page]
                && Object.keys(pagedFunctionaries[page]).sort((a, b): number => {
                  return functionaryCompare(
                    pagedFunctionaries[page][a],
                    pagedFunctionaries[page][b],
                    this.props.locale.appLanguage.langName
                  );
                }).map((key: string) => (
                  this._renderFunctionaryRow(pagedFunctionaries[page][key])))
              }
            </TableBody>
          </ResponsiveTable>
        </ScrollableTableContainer>

        {this._renderPagination()}
      </>
    );
  }

  private _renderFunctionaryRow = (functionary: Functionary) => {
    const functionaryObject = new FunctionaryObject(functionary);
    const {
      targetIdParams: {
        clubId
      },
    } = this.props;

    let precision = functionaryObject.getTitlePrecision();
    return (
      <TableRow key={functionaryObject.getId()}>
        <StickyCell align="left">
          {functionaryObject.getFunctionaryTitle()}
          {precision ? ' (' + precision + ')' : ''}
        </StickyCell>
        <InteractiveCell align="right">
          {functionaryObject.getName()}
        </InteractiveCell>
        <InteractiveCell align="right">{functionaryObject.getPhone()}</InteractiveCell>
        <InteractiveCell align="right">{functionaryObject.getEmail()}</InteractiveCell>
        <InteractiveCell align="right">{functionaryObject.getStatus()}</InteractiveCell>
        <RoleScopeGuard>
          {({ isFederationScope, isClubScope }) => {
            const canEdit = Boolean((isClubScope && clubId) || isFederationScope);
            return canEdit && (
              <InteractiveCell align="right">
                <EditIconButton
                  onClick={() => {
                    this.setState({
                      functionaryIdToEdit: functionaryObject.getId(),
                      modalOpen: true
                    });
                  }}
                />
              </InteractiveCell>
            );
          }}
        </RoleScopeGuard>
      </TableRow>
    );
  }

  private _renderPagination = () => {
    const {
      functionariesState: {
        pagination: {
          page,
          limit,
          totalCount
        }
      }
    } = this.props;

    if (totalCount > limit) {
      return <Pagination page={page} limit={limit} totalCount={totalCount} onPageChange={this._handlePageChange} />;
    }

    return null;
  }

  private _handlePageChange = (page: number) => {
    const {
      functionariesState: {
        pagedFunctionaries,
        searchActive,
        search
      },
      fetchFunctionaries,
      fetchSearch,
      changePage,
      locale: {
        appLanguage: {
          collation
        }
      }
    } = this.props;

    /*
      If the page was already fetched we don't need to get it again.
      Just dispatch an action to change page.
     */
    if (pagedFunctionaries[page]) {
      changePage({ page });
    } else if (searchActive) {
      fetchSearch({ page, params: { ...search, collation } });
    } else {
      fetchFunctionaries({ page, params: { collation } });
    }
  }
}

export default injectIntl(withRouter(connect((state: StoreState) => ({
  functionariesState: state.functionariesReducer,
  locale: state.locale
}), {
  fetchFunctionaries: functionariesActions.fetchFunctionaries,
  changePage: functionariesActions.changePage,
  searchChanged: functionariesActions.searchChanged,
  fetchSearch: functionariesActions.fetchSearch,
  resetSearch: functionariesActions.resetSearch,
  setTargetIdParams: functionariesActions.setTargetIdParams,
})(Functionaries)));