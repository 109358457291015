import React, { Component, Fragment } from 'react';
import { FormattedMessage, WrappedComponentProps, injectIntl } from 'react-intl';
import { ContentWrap, ContentHeader, ContentHeaderTitle, ContentLoader } from '@src/components/layouts/ui';
import {
  Button,
  TableHead,
  TableBody,
  TableRow,
} from '@material-ui/core';
import {
  ScrollableTableContainer,
  ResponsiveTable,
  StickyCell,
  InteractiveCell
} from '@src/components/shared/ScrollableTable';
import { connect } from 'react-redux';
import * as partnersActions from '@src/store/partners/actions';
import * as partnerActions from '@src/store/partner/actions';
import { DEFAULT_PAGINATION_PAGE } from '@src/assets/config';
import { Pagination } from '@src/components/pagination/Pagination';
import { PartnerModal } from '@src/components/modals/PartnerModal';
import { confirm } from '@src/components/modals/Confirm';
import HeadHelmet from '@src/components/seo/HeadHelmet';
import { DeleteIconButton, EditIconButton } from '@src/components/buttons/buttons';
import { partnerCompare } from '@src/utils/partnerUtils';

interface Props {
  partners: PartnersState;
  fetchPartners: (params: TablePaginationAction) => any;
  changePage: (params: ChangePageAction) => any;
  editPartner: (params: PartnerEdit) => any;
  addPartner: (params: PartnerAdd) => any;
  removePartner: (params: PartnerDelete) => any;
  locale: AppLocale;
}

interface State {
  isEditPartnerDialogOpen?: boolean;
  isNewPartnerDialogOpen?: boolean;
  partnerIdToEdit?: number;
  modalOpen: boolean;
}

class PartnersConnected extends Component<Props & WrappedComponentProps, State> {
  constructor(props: Props & WrappedComponentProps) { 
    super(props);

    this.state = {
      modalOpen: false,
    };
  }

  UNSAFE_componentWillMount() {
    const {
      partners: {
        requesting,
        successful
      },
      fetchPartners,
      locale: {
        appLanguage: {
          collation
        }
      }
    } = this.props;

    if (!requesting && !successful) {
      fetchPartners({
        page: DEFAULT_PAGINATION_PAGE,
        params: { collation }
      });
    }
  }

  render() {
    const { 
      partners: {
        requesting
      }
    } = this.props;
    const { partnerIdToEdit, modalOpen } = this.state;

    return (
      <Fragment>
        <HeadHelmet titleId={'navigation.partners'} />
        <ContentWrap>
          <ContentHeader>
            <ContentHeaderTitle><FormattedMessage id={'scenes.partners.sectionTitle'} /></ContentHeaderTitle>
            <Button variant="contained" onClick={() => this.setState({partnerIdToEdit: undefined, modalOpen: true})}>
              <FormattedMessage id={'scenes.partners.addNewPartnerButtonLabel'} />
            </Button>
          </ContentHeader>

          {this._renderPartnersTable(this.props)}

          <ContentLoader visible={requesting}/>

          <PartnerModal
            partnerId={partnerIdToEdit}
            open={modalOpen}
            onClose={() => {
              this.setState({
                modalOpen: false,
              });
            }}
          />
        </ContentWrap>
      </Fragment>
    );
  }

  private _renderPartnersTable = (props: Props) => {
    const {
      partners: {
        requesting,
        successful,
        pagedPartners,
        pagination: {
          page
        }
      }
    } = props;

    if (!requesting) {
      // ToDo: Implement error handling
      return (
        <>
          <ScrollableTableContainer>
            <ResponsiveTable>
              <TableHead>
                <TableRow>
                  <StickyCell><FormattedMessage id={'scenes.partners.table.headers.name'} /></StickyCell>
                  <InteractiveCell><FormattedMessage id={'scenes.partners.form.usersLabel'} /></InteractiveCell>
                  <InteractiveCell size={'small'}/>
                  <InteractiveCell size={'small'}/>
                </TableRow>
              </TableHead>
              <TableBody>
                {successful && pagedPartners && Object.keys(pagedPartners[page]).sort((a, b): number => {
                  return partnerCompare(
                      pagedPartners[page][a],
                      pagedPartners[page][b],
                      this.props.locale.appLanguage.langName
                );
                }).map((key: string) => (
                  <TableRow key={pagedPartners[page][key].id}>
                    <StickyCell>{pagedPartners[page][key].name}</StickyCell>
                    <InteractiveCell>({pagedPartners[page][key].users.length})</InteractiveCell>
                    <InteractiveCell size={'small'}>
                      <EditIconButton
                        onClick={() => this.setState({partnerIdToEdit: pagedPartners[page][key].id, modalOpen: true})}
                      />
                    </InteractiveCell>
                    <InteractiveCell size={'small'}>
                      <DeleteIconButton
                        disabled={pagedPartners[page][key].users.length > 0}
                        onClick={() => {
                          const { id, name } = pagedPartners[page][key];
                          this._handleDestroyClick(id, name);
                        }}
                      />
                    </InteractiveCell>
                  </TableRow>
                ))}
              </TableBody>
            </ResponsiveTable>
          </ScrollableTableContainer>
          {this._renderPagination()}
        </>
      );
    } else {
      return null;
    }
  }

  private _handleDestroyClick = (id: number, name: string) => {
    const { removePartner, intl } = this.props;
    confirm({
      message: intl.formatMessage({
        id: 'scenes.partners.deletePartnerMessage',
      }, {
        name
      }),
      options: {
        cancelText: intl.formatMessage({ id: 'buttons.cancel' }),
        okText: intl.formatMessage({ id: 'buttons.ok' }),
      }
    }).then((result: any) => {
      if (result) {
        removePartner({
          id
        });
      }
    }, () => {
      window.console.log('cancelled');
    });
  }

  private _handlePageChange = (page: number) => {
    const {
      partners: {
        pagedPartners
      },
      fetchPartners,
      changePage,
      locale: {
        appLanguage: {
          collation
        }
      }
    } = this.props;

    if (pagedPartners[page]) {
      changePage({ page });
    } else {
      fetchPartners({ page, params: { collation } });
    }
  }

  private _renderPagination = () => {
    const {
      partners: {
        pagination: {
          page,
          limit,
          totalCount
        }
      }
    } = this.props;

    return <Pagination page={page} limit={limit} totalCount={totalCount} onPageChange={this._handlePageChange}/>;
  }
}

const Partners = injectIntl(connect((state: any) => ({
  partners: state.partnersReducer,
  locale: state.locale
}), {
  fetchPartners: partnersActions.fetchPartners,
  changePage: partnersActions.changePage,
  editPartner: partnerActions.editPartner,
  removePartner: partnerActions.deletePartner,
  addPartner: partnerActions.addPartner,
})(PartnersConnected));

export {
  Partners,
};