import React from 'react';
import { useQuery } from 'react-query';
import { Tooltip, CircularProgress } from '@material-ui/core';
import useAuthToken from '@src/hooks/useAuthToken';

interface CognitoStatusColumnProps {
  personId: number;
  visibleInTable?: boolean;
}

const CognitoStatusColumn = ({ personId, visibleInTable = true }: CognitoStatusColumnProps) => {
  const token = useAuthToken();

  const { data, isLoading, isError } = useQuery(
    ['cognitoStatus', personId],
    async () => {
      const response = await fetch(
        `/api/person/${personId}/cognito-status`,
        {
          method: 'GET',
          headers: {
            'Authorization': `Bearer ${token}`,
          },
        }
      );

      if (!response.ok) {
        throw new Error('Network response was not ok');
      }

      return await response.json();
    },
    {
      refetchOnWindowFocus: false,
      retry: 1,
      enabled: visibleInTable,
      staleTime: 30000,
    }
  );

  if (isLoading) {
    return (
      <div style={{ display: 'flex', justifyContent: 'center' }}>
        <CircularProgress size={20} />
      </div>
    );
  }

  if (isError || data?.awsError) {
    return (
      <Tooltip title="Golf-ID tietoja ei voida näyttää">
        <div style={{ textAlign: 'right' }}>Golf-ID: -</div>
      </Tooltip>
    );
  }

  const hasCognitoUser = data?.hasCognitoUser;
  const userStatus = data?.cognitoUser?.UserStatus;

  if (!hasCognitoUser) {
    return (
      <Tooltip title="Ei Golf-ID:tä">
        <div style={{ textAlign: 'right' }}>Golf-ID: Ei</div>
      </Tooltip>
    );
  }

  switch (userStatus) {
    case 'CONFIRMED':
      return (
        <Tooltip title="Golf-ID aktiivinen">
          <div style={{ color: '#4caf50', textAlign: 'right' }}>Golf-ID: Kyllä</div>
        </Tooltip>
      );
    case 'RESET_REQUIRED':
    case 'FORCE_CHANGE_PASSWORD':
      return (
        <Tooltip title="Salasanan vaihto vaaditaan">
          <div style={{ color: '#f50057', textAlign: 'right' }}>Golf-ID: Reset</div>
        </Tooltip>
      );
    default:
      return (
        <Tooltip title={`Golf-ID tila: ${userStatus}`}>
          <div style={{ color: '#ff9800', textAlign: 'right' }}>Golf-ID: {userStatus}</div>
        </Tooltip>
      );
  }
};

export default CognitoStatusColumn;