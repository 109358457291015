import React, { PureComponent, Fragment, ReactNode, ChangeEvent } from 'react';
import { FormattedMessage } from 'react-intl';
import {
  TableHead,
  TableBody,
  TableRow,
  Button,
  FormControl,
  TextField
} from '@material-ui/core';
import {
  ScrollableTableContainer,
  ResponsiveTable,
  StickyCell,
  InteractiveCell
} from '@src/components/shared/ScrollableTable';
import Select from '@src/components/forms/Select';
import HeadHelmet from '@src/components/seo/HeadHelmet';
import {
  ContentHeader,
  ContentHeaderTitle,
  ContentLoader,
  ContentWrap,
  SearchWrapper,
  SearchActions
} from '@src/components/layouts/ui';
import { connect } from 'react-redux';
import * as companiesActions from '@src/store/companies/actions';
import { DEFAULT_PAGINATION_PAGE } from '@src/assets/config';
import { CompanyModal } from '@src/components/scenes/federation/company/components/CompanyModal';
import { Pagination } from '@src/components/pagination/Pagination';
import { handleSearchFieldOnKeyDownEnterSniff } from '@src/utils/storeUtils';
import { EditIconButton } from '@src/components/buttons/buttons';
import { getActiveFederationId } from '@src/store/auth/selectors';
import { companyCompare } from '@src/utils/CompanyUtils';

type Props = {
  federationId?: number;
  companies: CompaniesState;
  fetchCompanies: (params: TablePaginationAction) => any;
  changePage: (params: ChangePageAction) => any;
  searchChanged: (params: FederationCompaniesSearch) => any;
  fetchSearch: (params: SearchPaginationAction) => any;
  resetSearch: (params: any) => any;
  locale: AppLocale;
};

type State = {
  modalOpen: boolean;
  companyIdToEdit?: number;
};

class FederationCompanies extends PureComponent<Props, State> {
  state = {
    modalOpen: false,
    companyIdToEdit: undefined
  };

  UNSAFE_componentWillMount() {
    const {
      companies: {
        requesting,
        successful,
      },
      fetchCompanies,
      federationId,
      locale: {
        appLanguage: {
          collation
        }
      }
    } = this.props;

    if (!requesting && !successful) {
      fetchCompanies({
        params: {
          federationId,
          collation
        },
        page: DEFAULT_PAGINATION_PAGE,
      });
    }
  }

  render() {
    const { companies } = this.props;
    const { modalOpen, companyIdToEdit } = this.state;

    return (
      <Fragment>
        <HeadHelmet titleId={'navigation.golfFederationCompanies'} />
        <ContentWrap>
          <ContentHeader>
            <ContentHeaderTitle>
              <FormattedMessage id={'navigation.golfFederationCompanies'} />
            </ContentHeaderTitle>
            <Button variant="contained" onClick={() => this.setState({companyIdToEdit: undefined, modalOpen: true})}>
              <FormattedMessage id={'scenes.golfFederation.companies.addNewCompanyButtonLabel'} />
            </Button>
            {this._renderCompanySearch()}
          </ContentHeader>

          <ContentLoader visible={companies.requesting}/>
          {this._renderCompanies()}
          {this._renderPagination()}

          <CompanyModal
            companyId={companyIdToEdit}
            open={modalOpen}
            onClose={() => {
              this.setState({
                modalOpen: false,
              });
            }}
            refetch={this._resetSearch}
          />
        </ContentWrap>
      </Fragment>
    );
  }

  private _renderCompanies = (): ReactNode => {
    const {
      companies: {
        requesting,
        successful,
        pagedCompanies,
        pagination: {
          page
        }
      }
    } = this.props;

    if (requesting) {
      return null;
    }

    return (
      <ScrollableTableContainer>
        <ResponsiveTable>
          <TableHead>
            <TableRow>
              <StickyCell><FormattedMessage id={'strings.name'} /></StickyCell>
              <InteractiveCell><FormattedMessage id={'strings.phone'} /></InteractiveCell>
              <InteractiveCell><FormattedMessage id={'strings.email'} /></InteractiveCell>
              <InteractiveCell><FormattedMessage id={'strings.state'} /></InteractiveCell>
              <InteractiveCell/>
            </TableRow>
          </TableHead>
          <TableBody>
            {successful && pagedCompanies[page] &&
            Object.keys(pagedCompanies[page]).sort((a, b): number => {
                return companyCompare(
                    pagedCompanies[page][a],
                    pagedCompanies[page][b],
                    this.props.locale.appLanguage.langName
                );
              }
            ).map((key: string) => (
              <TableRow key={pagedCompanies[page][key].id}>
                <StickyCell>{pagedCompanies[page][key].name}</StickyCell>
                <InteractiveCell>{pagedCompanies[page][key].phone}</InteractiveCell>
                <InteractiveCell>{pagedCompanies[page][key].email}</InteractiveCell>
                <InteractiveCell>{pagedCompanies[page][key].status}</InteractiveCell>
                <InteractiveCell size={'small'}>
                  <EditIconButton
                    onClick={() =>
                      this.setState({
                        companyIdToEdit: pagedCompanies[page][key].id,
                        modalOpen: true,
                      })
                    }
                  />
                </InteractiveCell>
              </TableRow>
            ))}
          </TableBody>
        </ResponsiveTable>
      </ScrollableTableContainer>
    );
  }

  private _renderCompanySearch = (): ReactNode => {
    const {
      companies: {
        requesting,
        search,
        searchActive
      },
    } = this.props;

    const disableSearch: boolean = this._disableSearch();

    return (
      <div style={{ display: 'none' }}>
        <SearchWrapper>
          <TextField
            disabled={requesting}
            label={<FormattedMessage id={'strings.searchTerm'} />}
            value={search.name}
            onChange={this._searchNameChanged}
            style={{ marginRight: '1em' }}
            onKeyDown={(e: React.KeyboardEvent<any>) =>
              handleSearchFieldOnKeyDownEnterSniff(e, this._performSearch, disableSearch)
            }
          />
          <FormControl disabled={requesting}>
            <Select
              selected={search.status}
              options={[
                {
                  id: 'INACTIVE',
                  name: <FormattedMessage id={'strings.statusInactive'}/>
                },
                {
                  id: 'ACTIVE',
                  name: <FormattedMessage id={'strings.statusActive'}/>
                }
              ]}
              onChangeCallback={(value) => {
                this._searchStatusChanged(value || '');
              }}
              labelText={<FormattedMessage id={'strings.state'}/>}
              placeholderText={<FormattedMessage id={'strings.state'}/>}
            />
          </FormControl>
          <SearchActions
            isSearchActive={searchActive}
            isApiRequestActive={requesting}
            performSearchAction={this._performSearch}
            resetSearchAction={this._resetSearch}
            isSearchDisabled={disableSearch}
          />
        </SearchWrapper>
      </div>
    );
  }

  private _performSearch = () => {
    const { fetchSearch, companies: { search }, federationId, locale: { appLanguage: { collation } } } = this.props;
    fetchSearch({
      page: DEFAULT_PAGINATION_PAGE,
      params: {
        ...search,
        federationId,
        collation
      },
    });
  }

  private _resetSearch = () => {
    const {
      resetSearch,
      federationId,
      locale: {
        appLanguage: {
          collation
        }
      }
    } = this.props;
    resetSearch({
      params: {
        federationId,
        collation
      },
    });
  }

  private _searchNameChanged = (e: ChangeEvent<HTMLInputElement>) => {
    const { searchChanged } = this.props;
    const {
      currentTarget: {
        value,
      }
    } = e;

    searchChanged({
      name: value
    });
  }

  private _searchStatusChanged = (value: any) => {
    const { searchChanged } = this.props;
    const status: ActivityStatus = value ? value : undefined;
    searchChanged({
      status
    });
  }

  private _disableSearch = (): boolean => {
    const {
      companies: {
        requesting,
        search,
      }
    } = this.props;

    return (requesting || (!search.name && !search.status));
  }

  private _renderPagination = (): ReactNode => {
    const {
      companies: {
        pagination: {
          page,
          limit,
          totalCount
        }
      }
    } = this.props;

    if (totalCount > limit) {
      return <Pagination page={page} limit={limit} totalCount={totalCount} onPageChange={this._handlePageChange}/>;
    }
    return null;
  }

  private _handlePageChange = (page: number) => {
    const {
      companies: {
        pagedCompanies,
        searchActive,
        search
      },
      fetchCompanies,
      fetchSearch,
      changePage,
      federationId,
      locale: {
        appLanguage: {
          collation
        }
      }
    } = this.props;

    /*
      If the page was already fetched we don't need to get it again.
      Just dispatch an action to chage page.
     */
    if (pagedCompanies[page]) {
      changePage({ page, params: { federationId } });
    } else if (searchActive) {
      fetchSearch({ page, params: { ...search, federationId, collation } });
    } else {
      fetchCompanies({ page, params: { federationId, collation } });
    }
  }
}

export default connect((state: StoreState) => ({
  companies: state.companiesReducer,
  federationId: getActiveFederationId(state),
  locale: state.locale
}), {
  fetchCompanies: companiesActions.fetchCompanies,
  changePage: companiesActions.changePage,
  searchChanged: companiesActions.searchChanged,
  fetchSearch: companiesActions.fetchSearch,
  resetSearch: companiesActions.resetSearch,
})(FederationCompanies);