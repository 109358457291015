import React from 'react';
import { BrowserRouter as Router, Route } from 'react-router-dom';
import { createGlobalStyle, ThemeProvider } from 'styled-components';
import { IntlProvider } from 'react-intl';
import localization from './localization';
import { flattenMessages } from './utils/flattenMessages';
import theme from './theme/theme';
import { Login } from '@src/scenes/authentication/Login';
import { ForgotPassword } from '@src/scenes/authentication/ForgotPassword';
import { AdminIndex } from '@src/scenes/admin';
import { Redirect, Switch } from 'react-router';
import { applyMiddleware, createStore, compose } from 'redux';
import { Provider } from 'react-redux';
import createSagaMiddleware from 'redux-saga';
import { indexReducer, indexSagas } from '@src/store';
import { LoginCheckRequest, loginCheckRequest } from '@src/store/login/actions';
import { connect } from 'react-redux';
import { LOCALSTORAGE_TOKEN_NAME, isProduction } from '@src/assets/config';
import { OverlayLoader } from '@src/components/layouts/ui';
import RouteLoginGuard from '@src/components/access-control/RouteLoginGuard';
import { Logout } from '@src/scenes/authentication/Logout';
import { ThemeProvider as MuiThemeProvider } from '@material-ui/core/styles';
import dateFnsLocaleFi from 'date-fns/locale/fi';
import dateFnsLocaleSv from 'date-fns/locale/fi';
import dateFnsLocaleEn from 'date-fns/locale/en-US';
import materialUITheme from './theme/materialUITheme';
import Layout from '@src/components/layouts/Layout';
import ResetPassword from '@src/scenes/authentication/ResetPassword';
import Utils from '@date-io/date-fns';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import NonProductionNotification from '@src/components/contexts/NonProductionNotification';
import { SnackbarProvider } from 'notistack';
import { QueryClient, QueryClientProvider } from 'react-query';

/*
  Unholy fi-SV hack due to the fact that window.Intl does not respoect sv-FI
 */
const dateFnsLocales = {
  'fi-FI': dateFnsLocaleFi,
  'fi-SV': {
    ...dateFnsLocaleSv,
    formatLong: {
      ...dateFnsLocaleSv.formatLong,
      date: (dateFnsLocaleFi.formatLong as any).date,
    },
  },
  'sv-SE': dateFnsLocaleSv,
  'en-US': dateFnsLocaleEn,
};

// Setup the middleware to watch between the Reducers and the Actions
const sagaMiddleware = createSagaMiddleware();

/* tslint:disable */
const composeSetup = !isProduction && typeof window === 'object' &&
  (window as any).__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ ?
  (window as any).__REDUX_DEVTOOLS_EXTENSION_COMPOSE__({ trace: true, traceLimit: 25 }) : compose;
/* tslint:enable */

const store = createStore(
  indexReducer,
  composeSetup(applyMiddleware(sagaMiddleware)), // allows redux devtools to watch sagas
);

export const queryClient = new QueryClient();

sagaMiddleware.run(indexSagas);

const GlobalStyles = createGlobalStyle`
  body {
    font-size: 62.5% !important;
    font-family: 'Source Sans Pro', sans-serif !important;
    font-weight: 400;
    text-size-adjust: 100%;
    text-rendering: optimizeLegibility;
    -webkit-tap-highlight-color: transparent; 
    margin: 0;
  }
  
  /* Table Responsive Styles */
  .table-container {
    width: 100%;
    overflow-x: auto;
    margin-bottom: 1em;
    -webkit-overflow-scrolling: touch;
    display: block;
    max-width: 100%;
    touch-action: pan-x; /* Improve touch scrolling */
    
    @media (max-width: 600px) {
      border: 1px solid rgba(224, 224, 224, 1);
      border-radius: 4px;
      margin-left: -8px;
      margin-right: -8px;
      width: calc(100% + 16px);
      position: relative; /* Help with z-index stacking */
      z-index: 1;
    }
  }
  
  table {
    width: 100%;
    table-layout: fixed;
    border-collapse: separate;
    border-spacing: 0;
    
    @media (max-width: 600px) {
      table-layout: auto;
      min-width: 650px; /* Ensure table has minimum width for scrolling */
      pointer-events: auto; /* Ensure touch events reach the table */
    }
    
    tbody, thead {
      tr {
        pointer-events: none; /* This makes rows ignore touch events for better scrolling */
        
        td, th {
          vertical-align: middle;
          overflow: hidden;
          text-overflow: ellipsis;
          pointer-events: auto; /* Re-enable touch events for cells */
          
          @media (max-width: 600px) {
            padding: 8px;
            font-size: 0.9em;
            white-space: nowrap;
          }
        }
      }
    }
    
    /* Sticky first column for better context */
    tbody tr td:first-child,
    thead tr th:first-child {
      @media (max-width: 600px) {
        position: sticky;
        left: 0;
        background-color: white;
        z-index: 1;
        box-shadow: 2px 0 5px -2px rgba(0,0,0,0.1);
      }
    }
  }
  
  /* Make MUI components responsive */
  .MuiCard-root {
    @media (max-width: 600px) {
      overflow: visible;
      width: 100%;
      margin: 8px 0;
      box-shadow: none;
      border: 1px solid rgba(0, 0, 0, 0.12);
    }
  }
  
  .MuiCardContent-root {
    @media (max-width: 600px) {
      padding: 12px;
      overflow: visible;
    }
  }
  
  .MuiInputBase-root {
    @media (max-width: 600px) {
      width: 100%;
      min-width: 0 !important;
      font-size: 14px;
    }
  }
  
  /* Form responsiveness */
  .MuiFormGroup-row {
    @media (max-width: 600px) {
      flex-direction: column;
      width: 100%;
      margin: 0;
    }
  }
  
  /* Grid responsiveness */
  .MuiGrid-container {
    @media (max-width: 600px) {
      width: 100%;
      margin: 0;
      overflow: visible;
    }
  }
  
  .MuiGrid-item {
    @media (max-width: 600px) {
      padding: 8px 0;
      width: 100%;
      max-width: 100%;
      flex-basis: 100%;
    }
  }
  
  /* Dialog responsiveness */
  .MuiDialog-paper {
    @media (max-width: 600px) {
      width: calc(100% - 32px) !important;
      margin: 16px !important;
      max-width: calc(100% - 32px) !important;
      max-height: calc(100% - 32px) !important;
      overflow-y: auto !important;
    }
  }
  
  /* Button responsiveness */
  .MuiButton-root {
    @media (max-width: 600px) {
      margin: 4px;
      font-size: 13px;
      padding: 6px 12px;
      white-space: nowrap;
    }
  }
  
  /* Button container alignment */
  .MuiDialogActions-root,
  .MuiCardActions-root,
  .action-buttons {
    @media (max-width: 600px) {
      display: flex;
      flex-wrap: wrap;
      justify-content: center;
      padding: 8px;
    }
  }
  
  /* Tab responsiveness */
  .MuiTab-root {
    @media (max-width: 600px) {
      min-width: 72px;
      padding: 6px 12px;
      font-size: 12px;
    }
  }
  
  /* Select responsiveness */
  .MuiSelect-root, .MuiSelect-select {
    @media (max-width: 600px) {
      width: 100%;
      min-width: 0 !important;
    }
  }
  
  /* Icon button responsiveness */
  .MuiFab-root {
    @media (max-width: 600px) {
      transform: scale(0.9);
    }
  }
  
  /* Fix scrolling issues */
  body, html {
    @media (max-width: 600px) {
      overflow-x: hidden;
      max-width: 100vw;
    }
  }
  
  .Select-value,
  .Select-placeholder {
    padding-left: 0 !important;
  }
  
  .Select-value {
    color: rgba(0, 0, 0, 0.87) !important;
  }
  
  .Select-menu {
	  [role="menuitem"] {
	    height: auto;
	  }
  }
  
  #select-portal-target > div {
    z-index: 3456;
  }
`;

interface StateProps {
  login: LoginState;
  locale: AppLocale;
}

interface DispatchProps {
  loginCheckRequest(args: LoginCheckRequest): void;
}

type Props = StateProps & DispatchProps;

class AppUnconnected extends React.Component<Props, {}> {

  constructor(props: Props) {
    super(props);
    try {
      const token = localStorage.getItem(LOCALSTORAGE_TOKEN_NAME);
      if (token) {
        this.props.loginCheckRequest({ token });
      }
    } catch (e) {
      window.console.warn('unable to parse token');
    }
  }

  render() {
    const {
      login: {
        checkRequesting
      },
      locale: {
        appLanguage
      },
    } = this.props;

    const { code } = appLanguage;
    // FIXME questionable cast
    const messages = flattenMessages(
      localization.find(i => i.code === code)?.data || {}
    ) as unknown as Record<string, string>;

    return (
      <ThemeProvider theme={theme}>
        <IntlProvider
          locale={code}
          messages={messages}
        >
          <MuiThemeProvider theme={materialUITheme}>
            <MuiPickersUtilsProvider utils={Utils} locale={dateFnsLocales[code]}>
              <SnackbarProvider maxSnack={3}>
                <>
                  <GlobalStyles />
                  <Router>
                    <Switch>
                      <Redirect from={`/`} to={`/login`} exact={true} />
                      <Route path={'/login'} component={Login} />
                      <Route path={'/logout'} component={Logout} />
                      <Route path={'/reset-password/:resetToken'} component={ResetPassword} />
                      <Route path={'/reset-password'} component={ForgotPassword} exact={true} />

                      <Layout>
                        {/*
                        Create Admin routes if admin. Login otherwise.
                      */}
                        <Route
                          path={'/admin'}
                          render={(props: any) => (
                            <RouteLoginGuard
                              {...props}
                              role={'admin'}
                              requiredRole={'admin'}
                              component={(
                                <AdminIndex />
                              )}
                            />
                          )}
                        />
                      </Layout>
                    </Switch>
                  </Router>

                  <NonProductionNotification />
                  <OverlayLoader visible={checkRequesting} />
                </>
              </SnackbarProvider>
            </MuiPickersUtilsProvider>
          </MuiThemeProvider>
        </IntlProvider>
      </ThemeProvider>
    );
  }
}

const AppConnected = connect<StateProps, DispatchProps, {}, StoreState>(state => ({
  login: state.loginReducer,
  locale: state.locale,
}), {
  loginCheckRequest,
})(AppUnconnected);

const App = () => (
  <QueryClientProvider client={queryClient}>
    <Provider store={store}>
      <AppConnected />
      <div id={'select-portal-target'} />
    </Provider>
  </QueryClientProvider>
);

export default App;
