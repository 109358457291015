import React, { PureComponent, Fragment, ReactNode } from 'react';
import { FormattedMessage } from 'react-intl';
import {
  TableHead,
  TableBody,
  TableRow,
  Button
} from '@material-ui/core';
import {
  ScrollableTableContainer,
  ResponsiveTable,
  StickyCell,
  InteractiveCell
} from '@src/components/shared/ScrollableTable';
import HeadHelmet from '@src/components/seo/HeadHelmet';
import {
  ContentHeader,
  ContentHeaderTitle, ContentLoader,
  ContentWrap
} from '@src/components/layouts/ui';
import { VendorModal } from '@src/components/scenes/federation/vendors/components/VendorModal';
import { VendorClubsModal } from '@src/components/scenes/federation/vendors/components/VendorClubsModal';
import { connect } from 'react-redux';
import * as vendorsActions from '@src/store/vendors/actions';
import { DEFAULT_PAGINATION_PAGE } from '@src/assets/config';
import { Pagination } from '@src/components/pagination/Pagination';
import { EditIconButton } from '@src/components/buttons/buttons';
import { vendorCompare } from '@src/utils/VendorUtils';

type Props = {
  vendors: VendorsState;
  fetchVendors: (params: TablePaginationAction) => any;
  changePage: (params: ChangePageAction) => any;
  resetVendors: () => any;
  locale: AppLocale;
};

type State = {
  modalOpen: boolean;
  clubDialogOpen: boolean;
  vendorIdToEdit?: number;
};

class FederationVendors extends PureComponent<Props, State> {
  state = {
    modalOpen: false,
    vendorIdToEdit: undefined,
    clubDialogOpen: false
  };

  UNSAFE_componentWillMount() {
    const {
      vendors: {
        requesting,
        successful,
      },
      fetchVendors,
      locale: {
        appLanguage: {
          collation
        }
      }
    } = this.props;

    if (!requesting && !successful) {
      fetchVendors({
        page: DEFAULT_PAGINATION_PAGE,
        params: { collation }
      });
    }
  }

  render() {
    const { vendors } = this.props;
    const { modalOpen, clubDialogOpen, vendorIdToEdit } = this.state;

    return (
      <Fragment>
        <HeadHelmet titleId={'navigation.golfFederationVendors'} />
        <ContentWrap>
          <ContentHeader>
            <ContentHeaderTitle>
              <FormattedMessage id={'navigation.golfFederationVendors'} />
            </ContentHeaderTitle>
            <Button variant="contained" onClick={() => this.setState({vendorIdToEdit: undefined, modalOpen: true})}>
              <FormattedMessage id={'scenes.golfFederation.vendors.addNewVendorButtonLabel'} />
            </Button>
          </ContentHeader>

          <ContentLoader visible={vendors.requesting}/>

          {this._renderVendors()}
          {this._renderPagination()}

          <VendorModal
            vendorId={vendorIdToEdit}
            open={modalOpen}
            onClose={() => {
              this.setState({
                modalOpen: false,
                vendorIdToEdit: undefined
              });
              this.props.resetVendors();
            }}
          />

          <VendorClubsModal
            vendorId={vendorIdToEdit}
            open={clubDialogOpen}
            onClose={() => {
              this.setState({
                clubDialogOpen: false
              });
            }}
          />
        </ContentWrap>
      </Fragment>
    );
  }

  private _renderVendors = (): ReactNode => {
    const {
      vendors: {
        requesting,
        successful,
        pagedVendors,
        pagination: {
          page
        }
      }
    } = this.props;

    if (requesting) {
      return null;
    }

    return (
      <ScrollableTableContainer>
        <ResponsiveTable>
          <TableHead>
            <TableRow>
              <StickyCell>
                <FormattedMessage id={'scenes.golfFederation.vendors.table.headers.name'} />
              </StickyCell>
              <InteractiveCell>
                <FormattedMessage id={'scenes.golfFederation.vendors.table.headers.username'} />
              </InteractiveCell>
              <InteractiveCell>
                <FormattedMessage id={'scenes.golfFederation.vendors.table.headers.scope'} />
              </InteractiveCell>
              <InteractiveCell>
                <FormattedMessage id={'scenes.golfFederation.vendors.table.headers.email'} />
              </InteractiveCell>
              <InteractiveCell align={'left'}>
                <FormattedMessage id={'scenes.golfFederation.vendors.table.headers.clubCount'} />
              </InteractiveCell>
              <InteractiveCell/>
            </TableRow>
          </TableHead>
          <TableBody>
            {successful && pagedVendors[page] &&
            Object.keys(pagedVendors[page]).sort((a, b): number => {
                return vendorCompare(
                    pagedVendors[page][a],
                    pagedVendors[page][b],
                    this.props.locale.appLanguage.langName
                );
              }
            ).map((key: string) => (
              <TableRow key={pagedVendors[page][key].id}>
                <StickyCell>{pagedVendors[page][key].name}</StickyCell>
                <InteractiveCell>{pagedVendors[page][key].username}</InteractiveCell>
                <InteractiveCell>{pagedVendors[page][key].scope}</InteractiveCell>
                <InteractiveCell>{pagedVendors[page][key].email}</InteractiveCell>
                <InteractiveCell align={'left'}>
                  <Button
                    disabled={!pagedVendors[page][key].clubCount}
                    color="primary"
                    onClick={() => this.setState({
                      vendorIdToEdit: pagedVendors[page][key].idVendor,
                      clubDialogOpen: true,
                    })}
                  >
                    {pagedVendors[page][key].clubCount}
                  </Button>
                </InteractiveCell>
                <InteractiveCell align={'left'} size={'small'}>
                  <EditIconButton
                    onClick={() =>
                      this.setState({
                        vendorIdToEdit: pagedVendors[page][key].id,
                        modalOpen: true,
                      })
                    }
                  />
                </InteractiveCell>
              </TableRow>
            ))}
          </TableBody>
        </ResponsiveTable>
      </ScrollableTableContainer>
    );
  }

  private _renderPagination = (): ReactNode => {
    const {
      vendors: {
        requesting,
        pagination: {
          page,
          limit,
          totalCount
        }
      }
    } = this.props;

    if (requesting) {
      return null;
    }

    if (totalCount > limit) {
      return <Pagination page={page} limit={limit} totalCount={totalCount} onPageChange={this._handlePageChange}/>;
    }

    return null;
  }

  private _handlePageChange = (page: number) => {
    const {
      vendors: {
        pagedVendors,
      },
      fetchVendors,
      changePage,
      locale: {
        appLanguage: {
          collation
        }
      }
    } = this.props;

    /*
      If the page was already fetched we don't need to get it again.
      Just dispatch an action to change page.
     */
    if (pagedVendors[page]) {
      changePage({ page });
    } else {
      fetchVendors({ page, params: { collation } });
    }
  }
}

export default connect((state: StoreState) => ({
  vendors: state.vendorsReducer,
  locale: state.locale
}), {
  fetchVendors: vendorsActions.fetchVendors,
  changePage: vendorsActions.changePage,
  resetVendors: vendorsActions.resetVendors,
})(FederationVendors);